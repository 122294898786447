import React, {useEffect, useState, useContext} from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkToc from 'remark-toc'
import rehypeSlug from 'rehype-slug'
import { useTheme } from '@mui/material/styles';
import rehypeHighlight from 'rehype-highlight';
import { getIsDarkMode } from "../features/App/StorageUtil";
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import {ErrorBoundary} from 'react-error-boundary';
import rehypeRaw from 'rehype-raw'
import {githubMarkdownDark, githubMarkdownLight} from "./githubmarkdown";

interface Props {
    tocHeader: string,
    markdown: string,
    setMarkdownIsInvalid?: React.Dispatch<React.SetStateAction<boolean>>,
    errorFallback: ({ error, resetErrorBoundary }: any) => JSX.Element
}

export default function MarkdownRenderer(props: Props) {

    const theme = useTheme();

    return (
        <React.Fragment>
            <style>
                {theme.palette.mode === 'dark' &&
                    githubMarkdownDark
                }
                {theme.palette.mode === 'light' &&
                    githubMarkdownLight
                }
            </style>
            <div className="markdown-body">
                <ErrorBoundary 
                    FallbackComponent={props.errorFallback}
                    onReset={() => props.setMarkdownIsInvalid? props.setMarkdownIsInvalid(false): {}}
                    onError={() => props.setMarkdownIsInvalid? props.setMarkdownIsInvalid(true): {}}
                >
                    <ReactMarkdown 
                        children={props.markdown} 
                        rehypePlugins={[[rehypeHighlight],[rehypeSlug],[rehypeKatex],[rehypeRaw]]} 
                        remarkPlugins={[[remarkGfm],[remarkToc, {heading: props.tocHeader}],[remarkMath]]}
                    />
                </ErrorBoundary>
            </div>
        </React.Fragment>
    )
}