import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useTheme } from '@mui/material/styles';
import TextareaAutosize from '@mui/base/TextareaAutosize';

interface Props {
    mt?: number,
    code: string,
    width?: string,
    height?: string
}

export default function CustomCodeEditor(props: Props) {

    const theme = useTheme();
    let codeArea = useRef<any>();

    useEffect(() => {
        if(theme.palette.mode === "dark"){
            document.documentElement.setAttribute('data-color-mode', 'dark');
        } else {
            document.documentElement.setAttribute('data-color-mode', 'light');
        }
    })

    useEffect(() => {
        if(codeArea !== undefined){
            codeArea.current.scrollTop = codeArea.current.scrollHeight;
        }
    },[props.code])

    return (
        <Box sx={{width: props.width? props.width: "100%", height: props.height? props.height: "100%", mt: props.mt? props.mt: 0, overflow: "hidden", border: "1px solid "+ theme.palette.divider}}>
            <TextareaAutosize  
                value={props.code}
                ref={codeArea}
                //maxRows="500" could be added for performance reasons. However it would cutoff the bottom
                style={{
                    fontSize: 14,
                    color: theme.palette.text.secondary,
                    border: "none",
                    outline: "none",
                    width: "100%",
                    height: "100%",
                    boxSizing: "border-box",
                    padding: "10px",
                    resize: "none",
                    overflow: 'auto',
                    backgroundColor: "transparent",
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
            />
        </Box>
    )
}