
/*******************
    UI Models
********************/
export enum PageType {
    PrivacyStatement = -2,
    LegalInformation = -1,
    Login = 0,

    //PageIDs from the database
    ManageUsers = 1,
    ManageJobs = 2,
    CompilerVisualization = 3,
    Documentation = 4,
    API = 5,
    Settings = 6,
    Logs = 7,
    EditDocumentation = 8
}

export enum TextLanguage {
    German = "Deutsch",
    English = "English"
}

export interface Group {
    groupID: number,
    name: string,
    isMember: boolean
}

export interface PageBase {
    name: string,
    pageType: PageType
}

export interface Page extends PageBase {
    isActive: boolean
}

export enum ModalType {
    Create = 0,
    Edit = 1,
    Delete = 2,
    View = 3,
    Cancel = 4,
    Error = 5,
    Success = 6,
    ForgotPassword = 7,
    EditPassword = 8,
    SendResetLink = 9,
    SuccessfulReset = 10
}

export interface Modal {
    isOpen: boolean,
    type: ModalType
}

export interface JobModal extends Modal {
    jobID: number | undefined,
    name: string | undefined,
    shots: string | undefined,
    language: Language | undefined,
    backendID: number | undefined,
    status: JobStatus | undefined
}

export interface Validation {
    passwordIsValid: boolean,
    emailIsValid: boolean
}

export enum StorageKeys {
    Theme = 'theme',
    Token = 'token',
    Pages = 'pages',
    Light = 'light',
    Dark = 'dark',
    True = 'true',
    False = 'false',
    Menu = 'menu',
    UserData = 'userdata',
    PageIDNameDict = 'pageIDNameDict'
} 

export interface Error {
    isVisible: boolean,
    message: string
}

export type AppContextType = {
    isDarkMode: boolean,
    isMenuOpen: boolean,
    userData: UserBase | undefined;
    setError: (error: Error) => void;
    setPageType: (pageType: PageType) => void;
    onLogout: () => void;
    onImprint: () => void;
    onPrivacyPolicy: () => void;
};

export enum ContentType {
    urlencoded = 'application/x-www-form-urlencoded',
    json = 'application/json'
}

export interface RequestData {
    url: string, 
    method: string, 
    contentType: ContentType, 
    body: URLSearchParams | string | undefined
}

/*******************
    User Models
********************/

export interface UserBase {
    full_name: string,
    username: string
    user_id: number,
    role_id: number
}

export interface User extends UserBase {
    is_active: boolean,
    role_name: string
    id: number   
}

export interface Backend {
    user_id: number,
    username: string,
    full_name: string,
    is_online: boolean, 
    description: string, 
    simulator: boolean,
    local: boolean,
    basis_gates: string[],
    supports_hybrid_jobs: boolean,
    max_n_qubits: number,
    max_shots: number,
    max_total_computation_time_seconds: number,
    max_intermediate_quantum_computation_time_seconds: number,
    max_intermediate_classical_computation_time_seconds: number
}

export interface UserModalType {
    user_id: number | undefined,
    username: string | undefined,
    full_name: string | undefined,
    password: string | undefined,
    is_active: boolean,
    role: string,
    page_ids: number[],
    group_ids: number[]
}

export interface UpdateUser {
    user_id: number,
    username: string | undefined,
    full_name: string | undefined,
    password: string | undefined,
    is_active: boolean,
    role: string,
    page_ids: number[]
}

export interface InsertUser {
    username: string,
    full_name: string,
    password: string,
    is_active: boolean,
    role: string,
    page_ids: number[]
}

export enum Role {
    Developer = 1,
    QuantumDevice = 2,
    Admin = 10
}

/*******************
    Job Models
********************/

export interface Job {
    job_id: number,
    name: string,
    shots: number,
    target: string,
    language: string,
    input_data: string,
    tiasm: string,
    user_error: string,
    admin_error: string,
    created_on: string,
    edited_on: string,
    status: string,
    result: string,
    backend_settings: any,
    settings_schema: any
}

export enum JobStatus {
    Submitted = 1,
    Running = 2,
    Completed = 3,
    Canceled = 4,
    Failed = 5,
    Deleted = 6,
    Cancelling = 7,
    Uncompiled = 8
}

export enum Language {
    OpenQASM2 = 'OpenQASM2',
    Python = 'Python',
    TIASM = 'TIASM'
}


/*******************
    Log Models
********************/
export interface Log {
    id: number,
    username: string | undefined,
    status_code: string,
    method: string,
    path: string,
    query_params: string | undefined,
    detail: string | undefined,
    stack_trace: string | undefined,
    timestamp: string,
    execution_time: number
}

/************************
    Backends
*************************/
export enum BackendStatusName {
    Idle = 'Idle',
    Executing = 'Executing',
    Calibrating = 'Calibrating',
    Unresponsive = 'Unresponsive'
}

export interface Backend {
    is_online: boolean,
    description: string,
    simulator: boolean,
    local: boolean,
    basis_gates: string[],
    supports_hybrid_jobs: boolean,
    max_n_qubits: number,
    max_shots: number,
    max_total_computation_time_seconds: number,
    max_intermediate_quantum_computation_time_seconds: number,
    max_intermediate_classical_computation_time_seconds: number,
    user_id: number,
    username: string,
    full_name: string,
    status_name: BackendStatusName,
    user_settings_schema: any
}

export enum WSTypes {
    print = "print",
    type = "type",
    jobID = "job_id",
    payload = "payload",
    token = "token",
    authentication = "authentication",
    filter = "filter",
    jobs = "jobs",
    add_print_listener = "add_print_listener",
    status_id = "status_id",
    error = "error",
    result = "result"
}