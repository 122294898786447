import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {ContentType, Job, JobModal, RequestData, Role} from '../../components/models';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import CustomCodeEditor from '../../components/CustomCodeEditor';
import { AppContext } from '../../components/AppContext';
import { fetchWrapper } from '../../components/utilities';

interface Props {
    handleClose: any,
    modal: JobModal
}

export default function ViewErrorModal(props: Props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [job, setJob] = useState(undefined as Job | undefined);
    const appContext = useContext(AppContext);

    useEffect(() => {
        if(props.modal.jobID === undefined) {
            throw 'Job ID is not defined.'
        }
        getJob(props.modal.jobID);
    },[props.modal]);

    function onSuccessfullJobLoading(data: any){
        let dataTemp = [...data];

        if(dataTemp.length === 1){
            setJob({...dataTemp[0]} as Job);
        } else {
            appContext.setError({isVisible: true, message: ""});
        }
    }

    function getJob(id: number){

        setIsLoading(true);

        let url = "";
        if(appContext.userData?.role_id === Number(Role.Admin)){
            url += "admin/jobs/?include_user_error=false&include_admin_error=true&job_id=";
        } else {
            url += "jobs/?include_user_error=false&job_id="
        }

        let requestData: RequestData = {
            url: url + id.toString(), method: "GET", contentType: ContentType.urlencoded, 
            body: undefined
        }
    
        fetchWrapper(
            requestData,
            onSuccessfullJobLoading,
            appContext,
            () => setIsLoading(false)
        );
    }

    return (
        <Dialog
          PaperProps={{
            style: {backgroundColor: theme.palette.background.paper, height: "100%"}
          }}
          fullWidth
          maxWidth={"lg"}
          fullScreen={fullScreen}
          open={props.modal.isOpen}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              props.handleClose();
            }
          }}
          aria-labelledby={"Edit Password"}
          onBackdropClick={undefined}
        >
          <DialogTitle id="responsive-dialog-title">
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Box>{props.modal.name}</Box>
                <IconButton onClick={props.handleClose}>
                    <ClearIcon/>
                </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{height: "100%", overflow: "auto", mb: 3}}>
            <DialogContentText sx={{height: "100%"}} component={"form"} id="updateUserForm">
                {isLoading &&
                    <Box sx={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", mb: 6}}>
                        <CircularProgress/>
                    </Box>
                }
                {!isLoading && job !== undefined &&
                    <Grid container rowSpacing={{xs: 6, md: 6, lg: 3}} columnSpacing={2} sx={{height: "100%"}}>
                        {/* <Grid item xs={12} md={appContext.userData?.role_id === Number(Role.Admin)? 6: 12} sx={{height: "100%"}} >
                            <Typography variant={"h6"} color={"text.secondary"} align={"center"} sx={{mb: 1}}>
                                {appContext.userData?.role_id === Number(Role.Admin)? "User error message" : "Error message"}
                            </Typography>
                            <CustomCodeEditor
                                code={job !== undefined? job.user_error: ""}
                                language={""}
                                disabled
                            />
                        </Grid> */}
                        {appContext.userData?.role_id === Number(Role.Admin) &&
                            <Grid item xs={12} md={12} sx={{height: "100%"}}>
                                <Typography variant={"h6"} color={"text.secondary"} align={"center"} sx={{mb: 1}}>
                                    Complete error message
                                </Typography>
                                <CustomCodeEditor
                                    code={job !== undefined? job.admin_error: ""}
                                    language={""}
                                    disabled
                                />
                            </Grid>
                        }
                    </Grid>
                }
            </DialogContentText>
          </DialogContent>
        </Dialog>
    );
}
