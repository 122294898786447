import React, {useContext, useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ContentType, Modal, ModalType, RequestData } from '../../components/models';
import { Box, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchWrapper } from '../../components/utilities';
import { AppContext } from '../../components/AppContext';
import { passwordTooWeakText } from '../../components/texts';
import {Validation} from '../../components/models';
import { BootstrapDialogTitle } from '../../components/CustomDialogTitle';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from 'react-router-dom';

interface Props {
    handleClose: any,
    modal: Modal,
    setModal: React.Dispatch<React.SetStateAction<Modal>>
}

export default function ForgotPasswordModal(props: Props) {
  const appContext = useContext(AppContext);
  let navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false); 
  const [validation, setValidation] = useState({passwordIsValid: true, emailIsValid: true} as Validation);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    if(props.modal.type === ModalType.EditPassword){
      const searchParams = new URLSearchParams(window.location.search);
      const tokenParams = searchParams.get('token');
      if(tokenParams !== null){
        setToken(tokenParams);
      }
    }
  },[])

  const handleSendResetLink = (event: any) => {
    event.preventDefault();

    let emailIsValid = theme.regex.email.test(email);

    setValidation({...validation, emailIsValid: emailIsValid});
    if(!emailIsValid){
      return
    }

    setIsLoading(true);

    let requestData: RequestData = {
      url: "user/password/reset_link/?username="+email, method: "GET", contentType: ContentType.json, 
      body: undefined
    }

    fetchWrapper(
      requestData,
      () => props.setModal({...props.modal, type: ModalType.SendResetLink}),
      appContext,
      () => setIsLoading(false)
    );
  }

  function handlePasswordSubmit(event: any) {

    event.preventDefault();

    if(password !== confirmPassword){
        return
    }
  
    let passwordIsValid = theme.regex.strongPassword.test(password);
  
    setValidation({...validation, passwordIsValid: passwordIsValid});
    if(!passwordIsValid){
        return
    }
  
    setIsLoading(true);

    let requestData: RequestData = {
      url: "user/password/?token="+token+"&password="+password, method: "PUT", contentType: ContentType.json, 
      body: undefined
    }

    fetchWrapper(
      requestData,
      () => props.setModal({...props.modal, type: ModalType.SuccessfulReset}),
      appContext,
      () => setIsLoading(false),
      () => setErrorOccurred(true)
    );
  }

  function handleEmailChange(e: any){
    let email = e.target.value;

    if(!validation.emailIsValid){
      let emailIsValid = theme.regex.email.test(email);
      setValidation({...validation, emailIsValid: emailIsValid});
    }
    setEmail(email);
  }

  function handlePasswordChange(e: any) {
    let password = e.target.value;

    if(!validation.passwordIsValid){
      let passwordIsValid = theme.regex.strongPassword.test(password);
      setValidation({...validation, passwordIsValid: passwordIsValid});
    }

    setPassword(password);
  }

  function handleConfirmPasswordChange(e: any) {
    let confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  }

  return (
      <Dialog
        PaperProps={{
          style: {backgroundColor: theme.palette.background.paper}
        }}
        fullScreen={fullScreen}
        open={props.modal.isOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.handleClose();
          }
        }}
        aria-labelledby={"Edit Password"}
        onBackdropClick={undefined}
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={props.handleClose}>
          {(props.modal.type === ModalType.ForgotPassword || props.modal.type === ModalType.EditPassword) && !errorOccurred? "Reset password": ""}
        </BootstrapDialogTitle>
        <DialogContent sx={{mt: 1, ml: 3, mr: 3}}>
          {props.modal.type === ModalType.ForgotPassword &&
            <DialogContentText component={"form"} id="updateUserForm" onSubmit={handleSendResetLink}>
              <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", ml:0, mr:0}}>
                  <Typography sx={{width: theme.customSizes.standardTextfield, mb: 2}}>Enter your email address to receive a reset link.</Typography>
                  <TextField 
                      required
                      name={"Email"} 
                      fullWidth
                      sx={{mt: 1, mb: 1}} 
                      label={"Email"} 
                      value={email}
                      error={!validation.emailIsValid} 
                      helperText={validation.emailIsValid? "": "You must enter a valid email address."}
                      onChange={handleEmailChange} 
                      inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
                  />
              </Box>
            </DialogContentText>
          }
        {props.modal.type === ModalType.EditPassword && !errorOccurred &&
            <DialogContentText component={"form"} id="updateUserForm" onSubmit={handlePasswordSubmit}>
              <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", ml:0, mr:0}}>
                  <Typography sx={{width: theme.customSizes.standardTextfield, mb: 2}}>Please enter your new password.</Typography>
                  <TextField 
                    required
                    name={"Password"} 
                    sx={{width: theme.customSizes.standardTextfield, mt: 1, mb: 1}} 
                    label={"Password"} 
                    value={password}
                    error={!validation.passwordIsValid} 
                    helperText={!validation.passwordIsValid? passwordTooWeakText : ""} 
                    onChange={handlePasswordChange} 
                    inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
                    type="password"
                />
                <TextField 
                    required
                    name={"RepeatPassword"} 
                    sx={{width: theme.customSizes.standardTextfield, mt: 1, mb: 1}} 
                    label={"Repeat password"} 
                    value={confirmPassword}
                    error={confirmPassword !== "" && password !== confirmPassword}
                    helperText={confirmPassword !== "" && password !== confirmPassword? "The passwords do not match.": ""}
                    onChange={handleConfirmPasswordChange} 
                    inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
                    type="password"
                />
              </Box>
            </DialogContentText>
          }
          {props.modal.type === ModalType.SendResetLink &&
            <Box sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <LockResetIcon style={{ fontSize: 60 }} color={"primary"}/>
              <Typography sx={{fontSize: "25px"}} color={"text.primary"}>Send reset link</Typography>
              <Typography align={"center"} sx={{mt: 2, mb: 4}}>We have sent an email to {email} with a reset link. Please note that it might take a few minutes for the email to arrive.</Typography>
            </Box>
          }
          {props.modal.type === ModalType.SuccessfulReset && !errorOccurred &&
            <Box sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <LockResetIcon style={{ fontSize: 60 }} color={"success"}/>
              <Typography sx={{fontSize: "25px"}} color={"text.primary"}>Your password has been reset</Typography>
              <Typography align={"center"} sx={{mt: 2, mb: 4}}>You can now log into the QVLS Cloud with your new password.</Typography>
            </Box>
          }
            {props.modal.type === ModalType.EditPassword && errorOccurred &&
            <Box sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <LockResetIcon style={{ fontSize: 60 }} color={"error"}/>
              <Typography sx={{fontSize: "25px"}} color={"text.primary"}>Something went wrong</Typography>
              <Typography align={"center"} sx={{mt: 2, mb: 4}}>Please go back and try to reset your password again.</Typography>
            </Box>
          }
        </DialogContent>
        {(props.modal.type === ModalType.ForgotPassword || props.modal.type === ModalType.EditPassword) && !errorOccurred &&
        <DialogActions sx={{justifyContent: "center", mb: 2}}>
          {isLoading &&
            <CircularProgress />
          }
          {!isLoading &&
            <React.Fragment>
              <Button disabled={!validation.emailIsValid} type="submit" form="updateUserForm" sx={{ml: 5, mr: 5, mb: 2}} style={{boxShadow: "none"}} fullWidth color={"primary"} variant={"contained"} autoFocus>
                {props.modal.type === ModalType.ForgotPassword? "Send reset link" : "Reset password"}
              </Button>
            </React.Fragment>
          }
        </DialogActions>
        }
      </Dialog>
  );
}