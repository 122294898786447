import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../components/AppContext";
import {ContentType, Log, PageType, RequestData} from "../../components/models";
import PageLayout from "../../components/PageLayout";
import { fetchWrapper } from "../../components/utilities";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DisplayDateTime from "../../components/DateTime";
import { HttpStatusComponent } from "../../components/StatusComponents";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,
        renderCell: (cellValues: any) =>  (
        <Tooltip title={cellValues.row.id} >
            <span className="MuiDataGrid-cellContent">{cellValues.row.id}</span>
        </Tooltip>)
    },
    { field: 'status_code', headerName: 'Status', width: 90, 
        renderCell: (cellValues: any) => {
            return (
                <HttpStatusComponent status={cellValues.row.status_code} showStatusName={true}/>
            )}
    },
    { field: 'method', headerName: 'Method', width: 90,         
        renderCell: (cellValues: any) =>  (
            <Tooltip title={cellValues.row.method} >
                <span className="MuiDataGrid-cellContent">{cellValues.row.method}</span>
            </Tooltip>)
    },
    { field: 'path', headerName: 'Path', minWidth: 150, flex: 1,     
        renderCell: (cellValues: any) =>  (
            <Tooltip title={cellValues.row.path} >
                <span className="MuiDataGrid-cellContent">{cellValues.row.path}</span>
            </Tooltip>)
    },
    { field: 'query_params', headerName: 'Query parameter', minWidth: 150, flex: 1,     
        renderCell: (cellValues: any) =>  (
            <Tooltip title={cellValues.row.query_params} >
                <span className="MuiDataGrid-cellContent">{cellValues.row.query_params}</span>
            </Tooltip>)
    },
    { field: 'username', headerName: 'Username', minWidth: 200, flex: 1, 
        renderCell: (cellValues: any) =>  (
            <Tooltip title={cellValues.row.username} >
                <span className="MuiDataGrid-cellContent">{cellValues.row.username}</span>
            </Tooltip>)
    },
    { field: 'detail', headerName: 'Detail', minWidth: 150, flex: 1, 
        renderCell: (cellValues: any) =>  (
            <Tooltip title={cellValues.row.detail} >
                <span className="MuiDataGrid-cellContent">{cellValues.row.detail}</span>
            </Tooltip>)
    },
    { field: 'stack_trace', headerName: 'Stack trace', minWidth: 150, flex: 1, 
        renderCell: (cellValues: any) =>  (
            <Tooltip title={cellValues.row.stack_trace} >
                <span className="MuiDataGrid-cellContent">{cellValues.row.stack_trace}</span>
            </Tooltip>
       )
    },
    { field: 'timestamp', headerName: 'Timestamp', width: 170,
        renderCell: (cellValues: any) => {
            return (
                <DisplayDateTime dateTime={cellValues.row.timestamp}/>
            )}
    },
    { field: 'execution_time', headerName: 'Execution time (s)', width: 150,         
        renderCell: (cellValues: any) =>  (
            <Tooltip title={cellValues.row.execution_time} >
                <span className="MuiDataGrid-cellContent">{cellValues.row.execution_time}</span>
            </Tooltip>
   )}
];

export default function Logs() {

    const appContext = useContext(AppContext);
    const [logs, setLogs] = useState([] as Log[]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [timespan, setTimespan] = useState(1 as number | undefined);

    function onSuccessfullUserLoading(data: any) {
        setLogs([...data]);
    }

    function getLogs(searchInput: string, timespan: number | undefined){
        setIsLoading(true);

        let url = "admin/logs/?include_stacktrace=true&search_input="+searchInput
        if(timespan !== undefined){
            url += "&timespan_hours="+timespan.toString()
        }

        let requestData: RequestData = {
            url: url, method: "GET", contentType: ContentType.urlencoded, 
            body: undefined
        }
    
        fetchWrapper(
            requestData,
            onSuccessfullUserLoading,
            appContext,
            () => setIsLoading(false)
        );
    }

    useEffect(() => {
        getLogs(searchInput, timespan);
    },[]);

    function onKeyDown(e: any) {
        if(e.keyCode === 13){
            getLogs(searchInput, timespan);
         }
    }

    function handleSearchChange(e: any) {
        setSearchInput(e.target.value);
    }

    function handleTimespanChange(e: any) {
        let input = e.target.value ;
        if(!input || ( input[input.length-1].match('[0-9]') && input[0].match('[1-9]')) ){
            if(input === ""){
                setTimespan(undefined);
            } else {
                setTimespan(input);
            }
        }
      }

    return (
        <PageLayout pageType={PageType.Logs}>
            <Box sx={{display: "flex", flexWrap: "wrap", mt:1}}>
                <TextField
                      sx={{alignSelf: "center", mr: 1, ml: 0, mb: 1}}
                      onChange={(e) => handleSearchChange(e)}
                      onBlur={() => getLogs(searchInput, timespan)}
                      onKeyDown={(e) => onKeyDown(e)}
                      size="small"
                      name="Search"
                      label="Search"
                      id="Search"
                      InputProps={{endAdornment: <IconButton sx={{paddingRight: 0}} onClick={() => getLogs(searchInput, timespan)}><SearchIcon color={"primary"}/></IconButton>}}
                    />
                    <TextField
                        placeholder={""}
                        onBlur={() => getLogs(searchInput, timespan)}
                        onKeyDown={(e) => onKeyDown(e)}
                        size="small"
                        name="Timespan (h)"
                        label="Timespan (h)"
                        sx={{mb: 1, width: "150px"}}
                        id="Timespan"
                        type={"number"}
                        value={timespan !== undefined? timespan: ""}
                        onChange={(e) => handleTimespanChange(e)}
                    />
            </Box>
            <DataGrid
                sx={{"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                    {
                      outline: "none !important",
                    }, mt: 0}}
                loading={isLoading}
                rows={logs}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
            /> 
        </PageLayout>
    )
}