import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {Modal, RequestData, ContentType} from '../../components/models';
import { Box, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchWrapper } from '../../components/utilities';
import { AppContext } from '../../components/AppContext';
import { useNavigate } from 'react-router-dom';

interface Props {
    handleClose: any,
    modal: Modal
}

export default function DeleteAccountModal(props: Props) {
  const theme = useTheme();
  let navigate = useNavigate();
  const appContext = useContext(AppContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSaving, setIsSaving] = useState(false);
  const [password, setPassword] = useState("");

  function onSuccessfullDelete(data: any) {
    props.handleClose()
    navigate("/")
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();

    setIsSaving(true);

    let requestData: RequestData = {
      url: "user/?password="+password, method: "DELETE", contentType: ContentType.json, 
      body: undefined
    }

    fetchWrapper(
      requestData,
      onSuccessfullDelete,
      appContext,
      () => setIsSaving(false)
    );
  }

  return (
      <Dialog
        PaperProps={{
          style: {backgroundColor: theme.palette.background.paper}
        }}
        fullScreen={fullScreen}
        open={props.modal.isOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.handleClose();
          }
        }}
        aria-labelledby={"Edit Password"}
        onBackdropClick={undefined}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete account"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={"form"} id="updateUserForm" onSubmit={handleSubmit}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", ml:0, mr:0}}>
              <Typography>Are you sure you want to continue? This cannot be undone.</Typography>
              <TextField 
                required
                name={"Password"} 
                sx={{mt: 3, mb: 0}} 
                label={"Password"} 
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)} 
                inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "center", mb: 1}}>
          {isSaving &&
            <CircularProgress/>
          }
          {!isSaving &&
            <React.Fragment>
              <Button type="submit" form="updateUserForm" sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} color={"primary"} variant={"outlined"} autoFocus>
                Continue
              </Button>
              <Button sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} color={"error"} variant={"outlined"} autoFocus onClick={props.handleClose}>
                Cancel
              </Button>
            </React.Fragment>
          }
        </DialogActions>
      </Dialog>
  );
}
