import React from "react";
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import qvls_logo_lightblue_small from '../../components/Logos/qvls_logo_lightblue_small.svg';
import qvls_logo_dark_small from '../../components/Logos/qvls_logo_dark_small.svg';
import MenuIcon from '@mui/icons-material/Menu';


const TopBarBase = styled('div')<{highlight: boolean, menuOpen: boolean}>`
    width: 100%;
    height: ${props => props.theme.spacing(6)};
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${props=> props.highlight? "0px": "0px"} solid ${props => props.theme.palette.divider};
    top: 0px;
    left: 0px;
    background-color: ${props => props.highlight? props.theme.customBackground.highlight: "none"};
`

const Logo =  styled('img')`
    width: ${props => props.theme.customSizes.iconSmall};
`


interface Props {
    menuOpen: boolean,
    isDarkMode: boolean,
    pageName: string,
    onClickMenu(): void
}

export default function TopBar(props: Props) {
    return (
        <TopBarBase 
          menuOpen={props.menuOpen} 
          highlight={true}
          sx={(theme) => ({
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginTop: 0,
            ...(props.menuOpen && {
                  transition: theme.transitions.create('margin', {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
              }),
              marginTop: -6,
              display: "hidden"
            })
          })}
        >
            <Box sx={{display: "flex", width: "100%"}}>    
                <IconButton color={"primary"} onClick={props.onClickMenu} sx={{ml: 1}}>
                    <MenuIcon />
                </IconButton>
                <Logo sx={{ml: 1, mr: 0.5}} alt="QVLS Logo" src={props.isDarkMode? qvls_logo_dark_small : qvls_logo_lightblue_small}/>
                <Divider color={props.isDarkMode? "white": "black"} sx={(theme) => ({height: "24px", alignSelf: "center", mr: 0.5})}  orientation='vertical'/>
                <Typography color={"text.primary"} sx={{fontSize: 18, alignSelf: "center", fontWeight: "500"}}>{props.pageName}</Typography>
            </Box>
        </TopBarBase>
    )
}