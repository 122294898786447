import React, {useContext, useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ContentType, Modal, ModalType, RequestData } from '../../components/models';
import { Box, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchWrapper } from '../../components/utilities';
import { AppContext } from '../../components/AppContext';
import Checkbox from '@mui/material/Checkbox';
import { passwordTooWeakText } from '../../components/texts';
import { Link } from 'react-router-dom';
import '../../components/css/custom-link.css'
import {Validation} from '../../components/models';
import { BootstrapDialogTitle } from '../../components/CustomDialogTitle';
import EmailIcon from '@mui/icons-material/Email';

interface Props {
    handleClose: any,
    modal: Modal,
    setModal: React.Dispatch<React.SetStateAction<Modal>>
}

export default function SignUpModal(props: Props) {
  const appContext = useContext(AppContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false); 
  const [validation, setValidation] = useState({passwordIsValid: true, emailIsValid: true} as Validation);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorOccurred, setErrorOccurred] = useState(false);

  useEffect(() => {
    if(props.modal.type === ModalType.Success){
      setIsLoading(true);
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');

      setIsLoading(true);

      let requestData: RequestData = {
        url: "user/activation/?token="+token, method: "PUT", contentType: ContentType.json, 
        body: undefined
      }

      fetchWrapper(
        requestData,
        () => props.setModal({...props.modal, type: ModalType.Success}),
        appContext,
        () => setIsLoading(false),
        () => setErrorOccurred(true)
      );
    }
  },[])

  const handleSubmit = (event: any) => {
    event.preventDefault();
    
    if(password !== confirmPassword){
      return
    }

    let passwordIsValid = theme.regex.strongPassword.test(password);
    let emailIsValid = theme.regex.email.test(email);

    setValidation({passwordIsValid: passwordIsValid, emailIsValid: emailIsValid});
    if(!emailIsValid || !passwordIsValid){
      return
    }

    setIsLoading(true);

    let userData = {username: email, password: password, full_name: fullName};

    let requestData: RequestData = {
      url: "user/", method: "POST", contentType: ContentType.json, 
      body: JSON.stringify(userData)
    }

    fetchWrapper(
      requestData,
      () => props.setModal({...props.modal, type: ModalType.View}),
      appContext,
      () => setIsLoading(false)
    );
  }

  function handlePasswordChange(e: any) {
    let password = e.target.value;

    if(!validation.passwordIsValid){
      let passwordIsValid = theme.regex.strongPassword.test(password);
      setValidation({...validation, passwordIsValid: passwordIsValid});
    }

    setPassword(password);
  }

  function handleEmailChange(e: any){
    let email = e.target.value;

    if(!validation.emailIsValid){
      let emailIsValid = theme.regex.email.test(email);
      setValidation({...validation, emailIsValid: emailIsValid});
    }
    setEmail(email);
  }

  function functionHandleCheckboxChange(event: any) {
    setIsChecked(event.target.checked);
  }

  function handleFullNameChange(e: any){
    setFullName(e.target.value);
  }

  function getLinkStyle() {
    return {color: theme.palette.mode === 'dark'? "rgba(255, 255, 255, 0.7)":"rgb(68 89 101)"}
  }

  function handleConfirmPasswordChange(e: any) {
    let confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  }

  return (
      <Dialog
        PaperProps={{
          style: {backgroundColor: theme.palette.background.paper}
        }}
        fullScreen={fullScreen}
        open={props.modal.isOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.handleClose();
          }
        }}
        aria-labelledby={"Edit Password"}
        onBackdropClick={undefined}
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={props.handleClose}>
          {props.modal.type === ModalType.Create? "Create account" : ""}
        </BootstrapDialogTitle>
        <DialogContent sx={{mt: 1, ml: 3, mr: 3}}>
          {props.modal.type === ModalType.Create &&
          <DialogContentText component={"form"} id="updateUserForm" onSubmit={handleSubmit}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", ml:0, mr:0}}>
              <TextField 
                required
                name={"Full name"} 
                sx={{width: theme.customSizes.standardTextfield, mt: 1, mb: 1}} 
                label={"Full name"} 
                value={fullName}
                onChange={handleFullNameChange}
                inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
              />
            <TextField 
                required
                name={"Email"} 
                sx={{width: theme.customSizes.standardTextfield, mt: 1, mb: 1}} 
                label={"Email"} 
                value={email}
                error={!validation.emailIsValid} 
                helperText={validation.emailIsValid? "": "You must enter a valid email address."}
                onChange={handleEmailChange} 
                inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
              />
            <TextField 
                required
                name={"Password"} 
                sx={{width: theme.customSizes.standardTextfield, mt: 1, mb: 1}} 
                label={"Password"} 
                value={password}
                error={!validation.passwordIsValid} 
                helperText={!validation.passwordIsValid? passwordTooWeakText : ""} 
                onChange={handlePasswordChange} 
                inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
                type="password"
            />
            <TextField 
                required
                name={"RepeatPassword"} 
                sx={{width: theme.customSizes.standardTextfield, mt: 1, mb: 1}} 
                label={"Repeat password"} 
                value={confirmPassword}
                error={confirmPassword !== "" && password !== confirmPassword}
                helperText={confirmPassword !== "" && password !== confirmPassword? "The passwords do not match.": ""}
                onChange={handleConfirmPasswordChange} 
                inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
                type="password"
            />
            <Box sx={{display: "flex", width: theme.customSizes.standardTextfield, flexDirection: "row", mt: 1, mb: 2}}>
              <Checkbox checked={isChecked} onChange={functionHandleCheckboxChange} inputProps = {{ 'aria-label': 'Checkbox demo' }}/>
              <Typography>I have read and agree to the <Link className="custom-link-2" style={{...getLinkStyle(), marginRight: "8px"}} to="/datenschutzerklaerung/en">Privacy Statement</Link>*</Typography>
            </Box>
            </Box>
          </DialogContentText>
          }
          {props.modal.type === ModalType.View &&
            <Box sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <EmailIcon style={{ fontSize: 60 }} color={"primary"}/>
              <Typography sx={{fontSize: "25px"}} color={"text.primary"}>Email Validation</Typography>
              <Typography align={"center"} sx={{mt: 2, mb: 4}}>We have sent an email to {email} to confirm the validity of your email address. After receiving the email follow the link provided to complete your registration. Please note that it might take a few minutes for the email to arrive.</Typography>
            </Box>
          }
          {props.modal.type === ModalType.Success && isLoading &&
            <Box sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <EmailIcon style={{ fontSize: 60 }} color={"primary"}/>
              <Typography sx={{fontSize: "25px", mb: 3}} color={"text.primary"}>Validating email</Typography>
              <CircularProgress sx={{mb: 1}} />
            </Box>
          }
          {props.modal.type === ModalType.Success && !errorOccurred && !isLoading &&
            <Box sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <EmailIcon style={{ fontSize: 60 }} color={"success"}/>
              <Typography sx={{fontSize: "25px"}} color={"text.primary"}>Email validated</Typography>
              <Typography align={"center"} sx={{mt: 2, mb: 4}}>Your registration is complete. You can now log into the QVLS Cloud.</Typography>
            </Box>
          }
          {props.modal.type === ModalType.Success && errorOccurred && !isLoading &&
            <Box sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <EmailIcon style={{ fontSize: 60 }} color={"error"}/>
              <Typography sx={{fontSize: "25px"}} color={"text.primary"}>Something went wrong</Typography>
              <Typography align={"center"} sx={{mt: 2, mb: 4}}>Your registration was not successfull. Please retry the account creation.</Typography>
            </Box>
          }
        </DialogContent>
        {props.modal.type === ModalType.Create &&
        <DialogActions sx={{justifyContent: "center", mb: 1}}>
          {isLoading &&
            <CircularProgress />
          }
          {!isLoading &&
            <React.Fragment>
              <Button disabled={!validation.emailIsValid || !validation.passwordIsValid || fullName === "" || !isChecked} type="submit" form="updateUserForm" sx={{ml: 4, mr: 4, mb: 2}} style={{boxShadow: "none"}} fullWidth color={"primary"} variant={"contained"} autoFocus>
                Create
              </Button>
            </React.Fragment>
          }
        </DialogActions>
        }
      </Dialog>
  );
}
