import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {ContentType, Job, JobModal, JobStatus, Language, RequestData, Role} from '../../components/models';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import CustomCodeEditor from '../../components/CustomCodeEditor';
import { AppContext } from '../../components/AppContext';
import { fetchWrapper } from '../../components/utilities';
import CustomResultViewer from '../../components/CustomResultViewer';
import JobStatusComponent from '../../components/StatusComponents';

interface Props {
    handleClose: any,
    modal: JobModal,
    jobPrints: string,
    jobError: string,
    jobResult: string,
    status: JobStatus | undefined
}

export default function ViewJobModal(props: Props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [job, setJob] = useState(undefined as Job | undefined);
    const appContext = useContext(AppContext);

    useEffect(() => {
        if(props.modal.jobID === undefined) {
            throw 'Job ID is not defined.'
        }
        getJob(props.modal.jobID);
    },[props.modal]);

    function onSuccessfullJobLoading(data: any){
        let dataTemp = [...data];

        if(dataTemp.length === 1){
            setJob({...dataTemp[0]} as Job);
        } else {
            appContext.setError({isVisible: true, message: ""});
        }
    }

    function getJob(id: number){

        setIsLoading(true);

        let url = "";
        if(appContext.userData?.role_id === Number(Role.Admin)){
            url += "admin/jobs/?include_backend_settings=true&include_results=true&include_tiasm=true&include_input_data=true&job_id=";
        } else {
            url += "jobs/?include_backend_settings=true&include_results=true&include_tiasm=true&include_input_data=true&job_id="
        }

        let requestData: RequestData = {
            url: url + id.toString(), method: "GET", contentType: ContentType.urlencoded, 
            body: undefined
        }
    
        fetchWrapper(
            requestData,
            onSuccessfullJobLoading,
            appContext,
            () => setIsLoading(false)
        );
    }

    function getOutput(prints: string, error: string, result: string){
        let output = ""
        if(prints !== ""){
            output = prints;
        }
        if(error !== ""){
            if(output === ""){
                output += error
            } else {
                output += "\n" + error
            }
        }
        if(result !== ""){
            if(output === ""){
                output += result
            } else {
                output += "\n" + result
            }
        }

        return output
    }

    return (
        <Dialog
          PaperProps={{
            style: {backgroundColor: theme.palette.background.paper, height: "100%"}
          }}
          fullWidth
          maxWidth={"lg"}
          fullScreen={true}
          open={props.modal.isOpen}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              props.handleClose();
            }
          }}
          aria-labelledby={"Edit Password"}
          onBackdropClick={undefined}
        >
          <DialogTitle id="responsive-dialog-title">
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    {props.status !== undefined &&
                        <JobStatusComponent status={JobStatus[props.status]} showStatusName={false}/>
                    }
                    <Box>{props.modal.name}</Box>
                </Box>
                <IconButton onClick={props.handleClose}>
                    <ClearIcon/>
                </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{height: "100%", overflow: "auto", mb: 3}}>
            <DialogContentText sx={{height: "100%"}} component={"form"} id="updateUserForm">
                {isLoading &&
                    <Box sx={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", mb: 6}}>
                        <CircularProgress/>
                    </Box>
                }
                {!isLoading && job !== undefined &&
                    <Grid container rowSpacing={{xs: 0, md: 0, lg: 0}} columnSpacing={1} sx={{height: "100%"}}>
                        <Grid item xs={12} md={9} sx={{height: "60%", mb: 2}} >
                            {/* <Typography variant={"h6"} color={"text.secondary"} align={"center"} sx={{mb: 1}}>
                                {job?.language + " Input"}
                            </Typography> */}
                            <CustomCodeEditor
                                code={job !== undefined? job.input_data: ""}
                                language={job?.language === Language.Python? "py": ""}
                                disabled
                            />
                        </Grid>
                        {/* {job?.language === Language.OpenQASM2 as string &&
                            <Grid item xs={12} md={4} sx={{height: "100%"}}>
                                <Typography variant={"h6"} color={"text.secondary"} align={"center"} sx={{mb: 1}}>
                                    TIASM
                                </Typography>
                                <CustomCodeEditor
                                    code={job !== undefined? job.tiasm: ""}
                                    language={"json"}
                                    disabled
                                />
                            </Grid>
                        } */}
                        <Grid item xs={12} md={3} sx={{height: "60%", mb: 2, overflow: "auto"}}>
                            <Typography variant={"h6"} color={"text.primary"} align={"left"} sx={{mb: 1}}>
                                Backend:
                            </Typography>
                            <Typography variant={"body1"} color={"text.primary"} align={"left"} sx={{mb: 3}}>
                                {job.target}
                            </Typography>
                            {job.backend_settings !== null && job.backend_settings !== undefined &&
                                <React.Fragment>
                                    <Typography variant={"h6"} color={"text.primary"} align={"left"} sx={{mb: 1}}>
                                        Settings:
                                    </Typography>
                                    {renderBackendSettings(job.backend_settings, job.settings_schema, job.language, job.shots)}
                                </React.Fragment>
                            }
                        </Grid>
                        <Grid item xs={12} md={12} sx={{height: "40%"}}>
                            {/* <Typography variant={"h6"} color={"text.secondary"} align={"center"} sx={{mb: 1}}>
                                Output
                            </Typography> */}
                            <CustomResultViewer
                                code={getOutput(props.jobPrints, props.jobError, props.jobResult)}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContentText>
          </DialogContent>
        </Dialog>
    );
}

function renderBackendSettings(backendSettings: any, settingsSchema: any, language: string, shots: number){

    if(backendSettings === null || backendSettings === undefined || settingsSchema === null || settingsSchema === undefined || !settingsSchema.hasOwnProperty("properties")){
        return (<React.Fragment/>)
    }

    return (
        <table style={{width: "100%"}}>
            <tbody>
                {language === Language.OpenQASM2 &&
                    <tr >
                        <td style={{textAlign: "left"}}>Shots:</td>
                        <td style={{textAlign: "left"}}>{shots}</td>
                    </tr>
                }

                {Object.keys(backendSettings).map((key, index) => {
                    return (
                        <tr key={index}>
                            <td style={{textAlign: "left"}}>{settingsSchema["properties"].hasOwnProperty(key) && settingsSchema["properties"][key].hasOwnProperty("title") ? settingsSchema["properties"][key]["title"]+":" : key+":"}</td>
                            <td style={{textAlign: "left"}}>{backendSettings[key] === null? "null": backendSettings[key].toString()}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}