import { Avatar } from "@mui/material";
import React from "react";
import { UserBase } from "./models";

interface Props {
    loggedInUser: UserBase | undefined,
    size: number,
    fontSize: number
}

export default function CustomAvatar(props: Props) {

    return (
        <React.Fragment>
            {props.loggedInUser !== undefined &&
                <Avatar sx={(theme) => ({bgcolor: theme.palette.text.secondary, width: props.size, height: props.size, fontSize:props.fontSize})}>
                    {props.loggedInUser.full_name.length > 0? props.loggedInUser.full_name[0] : ""}
                </Avatar>
            }
            {props.loggedInUser === undefined &&
                <Avatar 
                    sx={(theme) => ({bgcolor: theme.palette.text.secondary, width: props.size, height: props.size, fontSize:props.fontSize})}
                    src="/broken-image.jpg" />
            }
        </React.Fragment>
    )
}