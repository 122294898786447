import { Box, Button, CircularProgress, MenuItem, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, {useContext, useState} from "react";
import {ContentType, Modal, ModalType, PageType, RequestData, Role, StorageKeys, UserBase} from "../../components/models";
import PageLayout from "../../components/PageLayout";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PasswordModal from "./PasswordModal";
import DeleteAccountModal from "./DeleteAccountModal";
import { AppContext } from "../../components/AppContext";
import { fetchWrapper } from "../../components/utilities";

interface Props {
    isDarkMode: boolean,
    setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>,
    handleUserDataChange(userData: UserBase): void
}

export default function Settings(props: Props) {

    //const [validation, setValidation] = useState({passwordIsValid: true, fullnameIsValid: true, usernameIsValid: true} as Validation);
    const appContext = useContext(AppContext);
    const [fullName, setFullName] = useState(appContext.userData === undefined? "" : appContext.userData.full_name);
    const [isSaving, setIsSaving] = useState(false);
    const [modal, setModal] = useState({isOpen: false, type: ModalType.Edit} as Modal);

    function handleThemeChange(event: React.MouseEvent<HTMLElement>, isDark: boolean) {
        if (isDark) {
          localStorage.setItem(StorageKeys.Theme, StorageKeys.Dark)
          props.setIsDarkMode(true);
        } else {
          localStorage.setItem(StorageKeys.Theme, StorageKeys.Light)
          props.setIsDarkMode(false);
        }
    }; 

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let newFullName = fullName;

        let newUserData = {full_name: newFullName};

        if(appContext.userData === undefined) {
            return;
        }

        setIsSaving(true);

        let requestData: RequestData = {
            url: "user/", method: "PUT", contentType: ContentType.json, 
            body: JSON.stringify(newUserData)
        }
    
        fetchWrapper(
            requestData,
            (data) => props.handleUserDataChange({...appContext.userData as UserBase, full_name: newFullName}),
            appContext,
            () => setIsSaving(false)
        );
        
        // fetch(apiUrl + "user/",{
        //   method: 'PUT',
        //   headers: {"content-type": "application/json", "Authorization": "Bearer " + props.token},
        //   body: JSON.stringify(newUserData)
        // })
        //   .then(res => res.json())
        //   .then((data) => {
        //     setIsSaving(false);
        //     props.handleUserDataChange({...appContext.userData as UserBase, full_name: newFullName});
        //   })
        //   .catch((e) => {
        //     setIsSaving(false);
        //   })
    
      }

    return (
        <PageLayout pageType={PageType.Settings}>
            <Box sx={{display: "flex", width: "100%", alignItems: "center", flexDirection: "column", mt: 4}}>
                {modal.isOpen && modal.type === ModalType.Edit &&
                    <PasswordModal modal={modal} handleClose={() => setModal({...modal, isOpen: false})}/>
                }
                {modal.isOpen && modal.type === ModalType.Delete &&
                    <DeleteAccountModal modal={modal} handleClose={() => setModal({...modal, isOpen: false})}/>
                }
                <Box sx={{display: "flex",  width: "65vmin", alignItems: "start", flexDirection: "column"}}>
                    <Typography sx={{mb: 2}} variant={"h5"} color={"text.primary"}>Preferences</Typography>
                    <Box sx={{display: "flex", width: "100%", alignItems: "center", flexDirection: "row", justifyContent: "space-between", mb: 6}}>
                        <Typography variant={"h6"} color={"text.secondary"}>Theme</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            value={props.isDarkMode}
                            exclusive
                            onChange={handleThemeChange}
                        >
                            <ToggleButton size={"small"} value={false}>
                              <LightModeIcon sx={{mr: 1}}/>
                              Light
                            </ToggleButton>
                            <ToggleButton size={"small"} value={true}>
                              <DarkModeIcon sx={{mr: 1}}/>
                              Dark
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Typography sx={{mb: 4}} variant={"h5"} color={"text.primary"}>My Account</Typography>
                    <Box component={"form"} id={"userForm"} onSubmit={handleSubmit} sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                        <TextField 
                            name="username" 
                            required
                            sx={{mb: 4}}
                            label="Username (Email)"
                            value={appContext.userData?.username} 
                            fullWidth
                            disabled
                        />
                        <TextField
                            required
                            value={appContext.userData !== undefined ?  Role[appContext.userData.role_id] : ""}
                            disabled
                            sx={{mb: 4}}
                            select
                            label="Role"
                            name={"role"}
                            fullWidth
                        >
                            {Object.values(Role).map((roleName) => 
                              typeof roleName === "string" &&
                                <MenuItem key={roleName} value={roleName}>
                                  {roleName}
                                </MenuItem>

                            )}
                        </TextField>
                        <TextField 
                            name={"full_name"} 
                            required 
                            sx={{mb: 4}}
                            fullWidth
                            label={"Full Name"} 
                            value={fullName} 
                            //inputProps={{maxLength: "60vmin"}} 
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center"}}>
                            {isSaving &&
                                <Box sx={{width: 190, display: "flex", mb:2, justifyContent: "center"}}>
                                    <CircularProgress size={30}/>
                                </Box>
                            }
                            {!isSaving &&
                                <Button type="submit" sx={{ml: 0.5, mr: 0.5, mb: 2, width: 190}} form="userForm" disabled={fullName === appContext.userData?.full_name} color={"primary"} variant={"outlined"} autoFocus>
                                    Save Changes
                                </Button>
                            }
                            <Button onClick={(e) => setModal({type: ModalType.Edit, isOpen: true})}startIcon={<EditIcon/>} sx={{ml: 0.5, mr: 0.5, mb: 2, width: 190}} color={"primary"} variant={"outlined"} autoFocus>
                                Edit Password
                            </Button>
                            <Button onClick={(e) => setModal({type: ModalType.Delete, isOpen: true})} startIcon={<DeleteIcon/>} sx={{ml: 0.5, mr: 0.5, mb: 2, width: 190}}  color={"error"} variant={"outlined"} autoFocus>
                                Delete Account
                            </Button>
                        </Box>
                    </Box>
                    {/* <TextField
                        required
                        value={user.role}
                        sx={fieldStyle}
                        select
                        label="Role"
                        name={"role"}
                        disabled
                        //onChange={(e) => setUser({...user, role: e.target.value})}
                    > */}
                    {/* </TextField> */}
                </Box>
            </Box>
        </PageLayout>
    )
}