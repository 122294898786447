import { AppContextType, RequestData, StorageKeys } from "./models";
import { StatusCodes } from "http-status-codes";

const apiUrl = process.env.REACT_APP_API_URL as string;
const wsUrl = process.env.REACT_APP_WS_URL as string;

export function fetchWrapper(requestData: RequestData, onSuccess: (data: any) => void, appContext:  AppContextType, onCleanup: () => void, onError?: () => void) {

    let status: StatusCodes = StatusCodes.OK;
    let token = localStorage.getItem(StorageKeys.Token);
    if(!token) {
        token = ""
    }

    fetch(apiUrl + requestData.url,{
        method: requestData.method,
        headers: {"content-type": requestData.contentType, "Authorization": "Bearer " + token},
        body: requestData.body
    })
    .then(res => {
        status = res.status;
        return res.json();
    })
    .then((data) => {
        if(status === StatusCodes.OK){
            onSuccess(data);
        } else if (status === StatusCodes.UNAUTHORIZED) {
            appContext.onLogout();
            if(onError !== undefined){
                onError();
            } else {
                appContext.setError({isVisible: true, message: typeof data.detail === 'string'? data.detail:""});
            }
        } else {
            if(onError !== undefined){
                onError();
            } else {
                appContext.setError({isVisible: true, message: typeof data.detail === 'string'? data.detail:""});
            }
        }
        onCleanup();
    })
    .catch((e) => {
        onCleanup();
        if(onError !== undefined){
            onError();
        } else {
            appContext.setError({isVisible: true, message: ""});
        }
    })
}

export function websocketWrapper(url: string, initialMessage: string | undefined, onMessage:(data: any) => void, onClose:(error: Event) => void, appContext: AppContextType){
    try {

        //1. Authentication.
        let token = localStorage.getItem(StorageKeys.Token);
        if(!token) {
            appContext.onLogout();
            appContext.setError({isVisible: true, message: ""});
        }

        let websocket = new WebSocket(wsUrl + url + "?token=" + token);

        websocket.onclose = (event) => {
            onClose(event);

            if(event.code === 1006){
                appContext.onLogout();
                appContext.setError({isVisible: true, message: "Not authenticated. Please sign in again."});
            }
        }

        //2. Define what happens when data is received.
        websocket.onmessage = (event) => {
            onMessage(JSON.parse(event.data));
        }

        //3. Define what happens directly after the websocket connection is open.
        websocket.onopen = () => {
            if(initialMessage !== undefined){
                websocket.send(initialMessage);
            }
        }

        return websocket;
    } catch (error) {
        console.log(error);
    }
}