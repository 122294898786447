import React, {useEffect, useState, useContext} from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkToc from 'remark-toc'
import rehypeSlug from 'rehype-slug'
import { useTheme } from '@mui/material/styles';
import rehypeHighlight from 'rehype-highlight';
import { getIsDarkMode } from "../App/StorageUtil";
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'
import {githubMarkdownDark, githubMarkdownLight} from "../../components/githubmarkdown";
import {ErrorBoundary} from 'react-error-boundary';
import rehypeRaw from 'rehype-raw'
import { Backend, ContentType, RequestData } from "../../components/models";
import { fetchWrapper } from "../../components/utilities";
import { AppContext } from "../../components/AppContext";
import BackendsRenderer from "./BackendsRenderer";

const LightCodeTheme = React.lazy(() => import('./LightCodeTheme'))
const DarkCodeTheme = React.lazy(() => import('./DarkCodeTheme'))

const CodeThemeSelector = ({children}: any) => {
    const isDarkMode = getIsDarkMode();
    return (
        <>
            <React.Suspense fallback={<React.Fragment></React.Fragment>}>
                {isDarkMode && <DarkCodeTheme/>}
                {!isDarkMode && <LightCodeTheme/>}
            </React.Suspense>
            {children}
        </>
    )
}

interface Props {
    markdown: string,
    setMarkdownIsInvalid?: React.Dispatch<React.SetStateAction<boolean>>,
    errorFallback: ({ error, resetErrorBoundary }: any) => JSX.Element
}

export default function MarkdownRenderer(props: Props) {

    const theme = useTheme();
    const appContext = useContext(AppContext);
    const [backends, setBackends] = useState([] as Backend[]);

    useEffect(() => {
        getBackends();
    },[])

    function onSuccessfullJobLoading(data: any) {
        setBackends([...data]);
    }

    function getBackends(){

        let requestData: RequestData = {
            url: "backends/", method: "GET", contentType: ContentType.urlencoded, 
            body: undefined
        }
    
        fetchWrapper(
            requestData,
            onSuccessfullJobLoading,
            appContext,
            () => {}
        );
    }

    return (
        <React.Fragment>
            <style>
                {theme.palette.mode === 'dark' &&
                    githubMarkdownDark
                }
                {theme.palette.mode === 'light' &&
                    githubMarkdownLight
                }
            </style>
            <div className="markdown-body">
                <CodeThemeSelector>
                    <ErrorBoundary 
                        FallbackComponent={props.errorFallback}
                        onReset={() => props.setMarkdownIsInvalid? props.setMarkdownIsInvalid(false): {}}
                        onError={() => props.setMarkdownIsInvalid? props.setMarkdownIsInvalid(true): {}}
                    >
                        <ReactMarkdown 
                            children={props.markdown} 
                            rehypePlugins={[[rehypeHighlight],[rehypeSlug],[rehypeKatex],[rehypeRaw]]} 
                            remarkPlugins={[[remarkGfm],[remarkToc],[remarkMath]]}
                            components={{
                                div({node, id, className, children, ...props}) {
                                    const match = /backends/.exec(id || '')
                                    return match ? (
                                        <BackendsRenderer
                                            backends={backends}
                                        />
                                    ) : (
                                      <div className={className} {...props}>
                                        {children}
                                      </div>
                                    )
                                  }
                            }}
                        />
                    </ErrorBoundary>
                </CodeThemeSelector>
            </div>
        </React.Fragment>
    )
}