import { Box, useTheme } from "@mui/material";
import React, {useEffect, useState} from "react";

const size: number = 80;
const radius: number = 16;
const graphViewBox = "0 " + (0).toString() + " " +size.toString()+" "+(size).toString();
const minX: number = radius;
const maxX: number = size-radius;
const junctionX: number = minX + (maxX - minX)/2;
const minY: number = radius;
const maxY: number = size-radius;
const junctionY: number = minY + (maxY - minY)/2;

interface Props1 {
    color?: string;
}

export default function IonLoader(props: Props1) {

    const [cd, setCd] = useState([[junctionX,junctionY],[1,0]]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateCoordinates();
        }, 10);
          
        return () => clearInterval(interval);
      }, [cd]);

    function updateCoordinates(){
        let coordinates: any = [...cd[0]];
        let coordinateChange: any = [...cd[1]];
        let newX = coordinates[0] + coordinateChange[0];
        let newY = coordinates[1] + coordinateChange[1];

        let isAtJunction = (newX === junctionX) && (newY === junctionY);
        if(coordinateChange[0] === 1 && coordinateChange[1] === 0 && isAtJunction){
            coordinateChange = [0,-1];
        } else if(newX === junctionX && newY === minY) {
            coordinateChange = [0, 1];
        } else if (coordinateChange[0] === 0 && coordinateChange[1] === 1 && isAtJunction){
            coordinateChange = [1, 0];
        } else if (newX === maxX && newY === junctionY){
            coordinateChange = [-1, 0];
        } else if (coordinateChange[0] === -1 && coordinateChange[1] === 0 && isAtJunction) {
            coordinateChange = [0, 1];
        } else if (newX === junctionX && newY === maxY){
            coordinateChange = [0, -1];
        } else if (coordinateChange[0] === 0 && coordinateChange[1] === -1 && isAtJunction) {
            coordinateChange = [-1, 0];
        } else if (newX === minX && newY === junctionY) {
            coordinateChange = [1, 0]
        }

        setCd([[newX, newY], [...coordinateChange]]);
    }

    return (
        <Ion
            x={cd[0][0]}
            y={cd[0][1]}
            color={props.color}
        />
    )
}

interface Props2 {
    x?: number;
    y?: number;
    color?: string;
}

export function Ion(props: Props2) {

    const theme = useTheme();

    return (
        <Box sx={{width: 30, height: 30}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={graphViewBox}
                id={"background"}
                className={"ions-input"}
            >
                <circle 
                    key={"circle"}
                    cx={props.x === undefined? junctionX: props.x} 
                    cy={props.y === undefined? junctionY: props.y} 
                    r={radius} 
                    fill={props.color === undefined? theme.palette.primary.main: props.color}
                />
            </svg>
        </Box>
    )
}