import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { PageType } from "./models";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { drawerWidth } from "../features/App/NavigationMenu";
import { AppContext } from "./AppContext";

interface Props {
    children: any,
    pageType: PageType,
    style?: React.CSSProperties | undefined
}

export default function PageLayout(props: Props) {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const appContext = useContext(AppContext);

    useEffect(() => {
        appContext.setPageType(props.pageType);
    },[]);

    function getCss(color: string) {
        let css = `
        .scroll::-webkit-scrollbar-track {
            margin-top: 48px;
            margin-bottom: 0px;
            background: transparent; 
        }
    
        ::-webkit-scrollbar-thumb {
            background: ${color}; 
            border-radius: 5px;
        }
    
        ::-webkit-scrollbar-thumb:hover {
            background: ${color}; 
        }
        `
        return css
    }

    return (
        <React.Fragment>
            <style>
                {getCss(theme.palette.divider)}
            </style>
            <Box 
                className={appContext.isMenuOpen? "scroll": ""}
                style={props.style} 
                sx={(theme) => ({
                    display: "flex", 
                    overflow: "auto", 
                    scrollbarColor: "black black", 
                    flexDirection: "column", 
                    justifyContent: "start", 
                    mt: appContext.isMenuOpen? 1 : 6+1, 
                    mr: 2, 
                    mb: 2, 
                    transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    marginLeft: !isLargeScreen? 2 : `-${drawerWidth-8*2}px`,
                    ...(appContext.isMenuOpen && {
                            transition: theme.transitions.create('margin', {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        marginLeft: 2,
                    }),
                    width: '100%'
                })}
            >
                {props.children}
            </Box>
        </React.Fragment>
    )
}