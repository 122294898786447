import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {ContentType, JobModal, Language, RequestData, Backend, BackendStatusName, Role} from '../../components/models';
import { Box, Button, DialogActions, MenuItem, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CustomCodeEditor from '../../components/CustomCodeEditor';
import { AppContext } from '../../components/AppContext';
import { fetchWrapper } from '../../components/utilities';
import Ajv from 'ajv';

const steps = [
    'Describe job',
    'Add code',
    'Select backend',
  ];
const step_0_height = 300;
const min_height = 140;
//Need a fixed height to enable smooth dialog height transitions.
const min_dialog_height = (9*41).toString() + "px";

const openQASMExampleCode = `OPENQASM 2.0;
include "qelib1.inc";
qreg q[2];
creg c[2];
h q[0];
cx q[0],q[1];
barrier q[0],q[1];
measure q[0] -> c[0];
measure q[1] -> c[1];`

const pythonExampleCode = `"""
Three qubit bit flip code:

Performs multiple stabilizer measurements (Z0Z1, Z1Z2) and performs corrections
based on the measurement results.
"""

from qiskit import QuantumCircuit
from qvls_sdk import upload, qpu
import json

#0. Encoding. We start in the state |000>. Last two qubits are the ancilla qubits.
qc = QuantumCircuit(5, 3)

# Perform an error!
qc.x(2)

#1. Perform stabilizer measurements
#Measure Z0 x Z1
qc.cx(0,3)
qc.cx(1,3)
qc.measure(3,0)

#Measure Z1 x Z2
qc.cx(1,4)
qc.cx(2,4)
qc.measure(4,1)

result = qpu.run(qc)
print(result)

#2. Apply corrections based on stabilizer measurements
if result.get('001',None) == 1:
    # -> (Z0Z1, Z1Z2) = (-1, 1) -> Correct with X0
    qc.x(0)
elif result.get('010',None) == 1:
    # -> (Z0Z1, Z1Z2) = (1, -1) -> Correct with X1
    qc.x(2)
elif result.get('011',None) == 1:
    # -> (Z0Z1, Z1Z2) = (-1, -1) -> Correct with X2
    qc.x(1)

result = qpu.run(qc)
print(result)

#3. Measure all qubits of the logical state (we should still be in the |000> state if everything worked!)
qc.measure(0,0)
qc.measure(1,1)
qc.measure(2,2)

result = qpu.run(qc)

upload(json.dumps(result))
`

interface Props {
    handleClose: any,
    modal: JobModal,
    getJobs(): void
}

export default function AddJob(props: Props) {
    const appContext = useContext(AppContext);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isSaving, setIsSaving] = useState(false);
    const [step, setStep] = useState(0);
    const [code, setCode] = useState(pythonExampleCode);
    const [backends, setBackends] = useState(undefined as undefined | Backend[]);
    const [backend, setBackend] = useState(0);
    const [shots, setShots] = useState(props.modal.shots === undefined? "" : props.modal.shots);
    const [language, setLanguage] = useState(props.modal.language === undefined? Language.Python : props.modal.language);
    const [jobName, setJobName] = useState(props.modal.name === undefined? "" : props.modal.name);
    const [isLoading, setIsLoading] = useState(false);
    const [backendSettings, setBackendSettings] = useState({} as any);
    const [backendSettingsErrorMessages, setbackendSettingsErrorMessages] = useState({} as any);
    const [backendDialogHeight, setBackendDialogHeight] = useState(min_height);

    useEffect(() => {
      const div = document.getElementById('backend-dialog');
      const height = div?.scrollHeight;
      if(height !== undefined && height !== backendDialogHeight){
        setBackendDialogHeight(min_height + height);
      }
    }, [backends, backendSettingsErrorMessages, backendDialogHeight, backend])

    function onSuccessfullJobLoading(data: any) {
      if(data.length !== 1){
        throw 'Job was not found in database.'
      }
      let jobData = {...data[0]}

      if(jobData.hasOwnProperty("backend_settings") && jobData.backend_settings !== null){
        for(let key in jobData.backend_settings){
          if(jobData.backend_settings[key] === null){
            jobData.backend_settings[key] = "null";
          } else {
            jobData.backend_settings[key] = jobData.backend_settings[key].toString();
          }
        }
        setBackendSettings(jobData.backend_settings);
      }
      setCode(jobData.input_data);
    }

    function getJob(id: number){

      setIsLoading(true);

      let url = "";
      if(appContext.userData?.role_id === Number(Role.Admin)){
          url += "admin/jobs/?include_backend_settings=true&include_results=false&include_tiasm=false&include_input_data=true&job_id=";
      } else {
          url += "jobs/?include_backend_settings=true&include_results=false&include_tiasm=false&include_input_data=true&job_id="
      }

      let requestData: RequestData = {
          url: url + id.toString(), method: "GET", contentType: ContentType.urlencoded, 
          body: undefined
      }
  
      fetchWrapper(
          requestData,
          onSuccessfullJobLoading,
          appContext,
          () => setIsLoading(false)
      );
    }

    function onSuccessfullBackendLoading(data: any) {
      //Only backends that are online and responsive can be selected.
      let dataTemp = [...data];
      if(appContext.userData?.role_id !== Role.Admin){
        dataTemp = [...data].filter(backend => backend.is_online && backend.status_name !== BackendStatusName[BackendStatusName.Unresponsive]);
      }
      setBackends([...dataTemp]);
      let initialBackendIndex = getInitialBackendIndex([...dataTemp]);
      setDefaultBackendSettings(dataTemp[initialBackendIndex], true);
      setbackendSettingsErrorMessages({});
      setBackend(initialBackendIndex);
    }

    function getBackends(){

      setIsLoading(true);

      let requestData: RequestData = {
        url: "backends/", method: "GET", contentType: ContentType.urlencoded, 
        body: undefined
      }

      fetchWrapper(
        requestData,
        onSuccessfullBackendLoading,
        appContext,
        () => setIsLoading(false)
      );
    }

    function handleBackendSettingsChange(e: any, key: string) {
      let temp = {...backendSettings};
      temp[key] = e.target.value;
      setBackendSettings(temp);
    }

    function handleSubmit(e: any) {
      if(backends === undefined){
        return
      }


      
      //Convert types to match schema
      let backendSettingsTemp = {...backendSettings};
      let user_settings_schema = {...backends[backend].user_settings_schema};

      for (const [key, value] of Object.entries(backendSettingsTemp)) {
        if(backendSettingsTemp[key] === "null"){
          backendSettingsTemp[key] = null;
          continue;
        }

        if(backendSettingsTemp[key] === ""){
          backendSettingsTemp[key] = null;
          continue;
        }

        let type = user_settings_schema["properties"][key]["type"];

        if(type === "number" || (Array.isArray(type) && type.includes("number"))){
          backendSettingsTemp[key] = Number(value);
        }
        if(type === "integer" || (Array.isArray(type) && type.includes("integer"))){
          backendSettingsTemp[key] = Number(value);
        }
        if(type === "boolean" || (Array.isArray(type) && type.includes("boolean"))){
          backendSettingsTemp[key] = (value === "true");
        }
      }

      //Validate backend settings
      let ajv = new Ajv();
      let validate = ajv.compile(user_settings_schema);
      let valid = validate(backendSettingsTemp);
      let errorMessages: any = {};
      if (!valid && validate.errors !== undefined && validate.errors !== null) {
        for (let i = 0; i < validate.errors.length; i++) {
          let key = validate.errors[i].instancePath;
          key = key.substring(1);
          errorMessages[key] = validate.errors[i].message;
        }
      }
      setbackendSettingsErrorMessages({...errorMessages});

      e.preventDefault();
      if(valid){
        uploadJob(backendSettingsTemp);
      }
    }

    function onSuccessfullJobAdd() {
      props.handleClose();
    }

    function uploadJob(backendSettings: any) {

      if(backends === undefined){
        return
      }

      let jobData: any = {
        "name": jobName,
        "target": backends[backend].username,
        "language": Language[language],
        "input_data": code
      }

      if(language === Language.OpenQASM2 || language === Language.TIASM){
        jobData["shots"] = shots
      }

      if(Object.keys(backendSettings).length !== 0){
        jobData["backend_settings"] = backendSettings;
      }

      setIsSaving(true);

      let requestData: RequestData = {
        url: "jobs/", method: "POST", contentType: ContentType.json, 
        body: JSON.stringify(jobData)
      }

      fetchWrapper(
        requestData,
        onSuccessfullJobAdd,
        appContext,
        () => setIsSaving(false)
      );
    }

    function handleShotChange(e: any) {
      if(backends === undefined){
        return;
      }
      let input = e.target.value ;
      if(!input || ( input[input.length-1].match('[0-9]') && input[0].match('[1-9]')) ){
        if(Number(input) <= backends[backend].max_shots){
          setShots(input);
        }
      }
    }

    function handleLanguageChange(e: any) {
      let newLanguage = e.target.value as Language;
      setLanguage(newLanguage);
      if(newLanguage === Language.OpenQASM2){
        setCode(openQASMExampleCode);
      }
      if(newLanguage === Language.Python){
        setCode(pythonExampleCode);
      }
    }

    function getInitialBackendIndex(backendList: Backend[]) {
      if(props.modal.backendID !== undefined){
        for(let i = 0; i < backendList.length; i++){
          if(Number(backendList[i].user_id) === props.modal.backendID){
            return i;
          }
        }
      }
      return 0;
    }

    function handleStepChange(newStep: number){
      setStep(newStep);
      let languageWasNotChanged = props.modal.language === language;
      if(newStep === 1 && newStep > step && props.modal.jobID !== undefined && languageWasNotChanged){
        getJob(props.modal.jobID)
      } else if (newStep === 2){
        getBackends()
      }
    }

    function setDefaultBackendSettings(backend: Backend, reuseSettings: boolean){
      let defaultSettings: any = {};
      if(reuseSettings){
        defaultSettings = {...backendSettings};
      }  
      if(backend.user_settings_schema !== undefined && backend.user_settings_schema !== null && backend.user_settings_schema.hasOwnProperty("properties")){
        let properties = backend.user_settings_schema["properties"];
        for(let key in properties){
          if(defaultSettings.hasOwnProperty(key)){
            continue;
          }

          if(properties[key].hasOwnProperty("default")){
            if(properties[key]["default"] === null){
              defaultSettings[key] = "null";
            } else {
              defaultSettings[key] = properties[key]["default"].toString();
            }
          }
        } 
      }
      setBackendSettings({...defaultSettings});
    }

    function handleBackendChange(e: any) {
      if(backends === undefined){
        return;
      }
      let backendIndex = Number(e.target.value);
      setDefaultBackendSettings(backends[backendIndex], false)
      setBackend(backendIndex);
    }

    return (
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: theme.palette.background.paper, 
              transition: theme.transitions.create('height', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              height: fullScreen? "100%": min_dialog_height,
              ...(step === 0 && {height: (step_0_height).toString() + "px"}),
              ...(step === 1 && {
                  transition: theme.transitions.create('height', {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                }),
                height: "100%"
              }),
             ...(step === 2 && {height: backendDialogHeight.toString() + "px"})
          }
          }}
          fullWidth
          maxWidth={"sm"}
          fullScreen={fullScreen}
          open={props.modal.isOpen}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              props.handleClose();
            }
          }}
          aria-labelledby={"Edit Password"}
          onBackdropClick={undefined}
        >
          <DialogTitle id="responsive-dialog-title">
            <Stepper activeStep={step}>
                {steps.map((label) => (
                <Step key={label}>
                     <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
          </DialogTitle>
          <DialogContent sx={{overflow: "hidden", height: "100%"}}>
            <DialogContentText component={"form"} id="add_job_form" sx={{overflow:"hidden", height: "100%"}}>
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", overflow:"hidden", height: "100%"}}>
                    {step === 0 &&
                        <React.Fragment>
                            <TextField
                                margin="normal"
                                required
                                sx={{mt: 3}}
                                fullWidth
                                name="Job name"
                                label="Job name"
                                id="jobname"
                                value={jobName}
                                onChange={(e) => setJobName(e.target.value)}
                            />
                            <TextField
                                margin={"normal"}
                                required
                                fullWidth
                                value={language}
                                select
                                label="Language"
                                name={"Language"}
                                onChange={(e) => handleLanguageChange(e)}
                             >
                                <MenuItem key={Language[Language.OpenQASM2]} value={Language.OpenQASM2}>
                                  {"OpenQASM 2.0"}
                                </MenuItem>
                                <MenuItem key={Language[Language.Python]} value={Language.Python}>
                                  {"Python (for hybrid job)"}
                                </MenuItem>
                            </TextField>
                        </React.Fragment>
                    }
                    {step === 1 && isLoading &&
                      <Box sx={{display: "flex", height: "100%", alignItems: "center"}}>
                        <CircularProgress/>
                      </Box>
                    }
                    {step === 1 && !isLoading &&
                        <CustomCodeEditor
                          code={code}
                          mt={1}
                          language={language === Language.Python? "py": ""}
                          handleCodeChange={setCode}
                        />
                    }
                    {step === 2 && isLoading &&
                      <Box sx={{display: "flex", height: "100%", alignItems: "center"}}>
                        <CircularProgress/>
                      </Box>
                    }
                    {step === 2 && !isLoading && (backends === undefined || backends.length === 0) &&
                      <Typography sx={{mt: 4, mb: 3}}>No backends are currently available. Please try again later.</Typography>
                    }
                    {step === 2 && !isLoading && backends !== undefined && backends !== null && backends.length > 0 &&
                       <div id={"backend-dialog"} style={{overflow: "auto", width: "100%"}}>
                        <TextField
                            margin={"normal"}
                            required
                            fullWidth
                            sx={{mt: 3, mb: 3}}
                            value={backend}
                            select
                            label="Backend"
                            name={"Backend"}
                            onChange={(e) => handleBackendChange(e)}
                        >
                          {backends.map((backend, index) => 
                            <MenuItem key={backend.user_id} value={index}>
                              {backend.full_name}
                            </MenuItem>
                          )}
                        </TextField>
                        {(language === Language.OpenQASM2 || (backends[backend].user_settings_schema !== null && backends[backend].user_settings_schema.hasOwnProperty("properties") && Object.keys(backends[backend].user_settings_schema["properties"]).length > 0)) &&
                          <Typography color={"text.secondary"} sx={{mb: 1}}>Settings:</Typography>
                        }
                        {language === Language.OpenQASM2 &&
                          <TextField
                              margin="normal"
                              required
                              InputProps={{
                                inputProps: { min: 0 }
                              }}
                              placeholder={"Enter number between 1-"+(backends[backend].max_shots).toString()}
                              fullWidth
                              name="Shots"
                              label="Shots"
                              sx={{mb: 1}}
                              id="Shots"
                              type={"number"}
                              value={shots === undefined ? "": shots}
                              onChange={(e) => handleShotChange(e)}
                          />
                        }
                        {backends[backend].user_settings_schema !== null && backends[backend].user_settings_schema !== undefined && backends[backend].user_settings_schema.hasOwnProperty("properties") &&
                          Object.keys(backends[backend].user_settings_schema["properties"]).map((key, index) => {
    
                            let property = backends[backend].user_settings_schema["properties"][key];
                            let type = property["type"] === "boolean" ? property["type"] : "text";

                            return (
                              <React.Fragment>
                                {type === "boolean" &&
                                  <TextField
                                    select
                                    key={key + "select"}
                                    value={backendSettings[key]}
                                    label={property.hasOwnProperty("title") ? property["title"] : key}
                                    margin="normal"
                                    required
                                    fullWidth
                                    onChange={(e) => handleBackendSettingsChange(e, key)}
                                  >
                                    <MenuItem value={"true"}>True</MenuItem>
                                    <MenuItem value={"false"}>False</MenuItem>
                                  </TextField>
                                }
                                {type !== "boolean" &&
                                  <TextField
                                    key={key + "text"}
                                    type={"text"}
                                    label={property.hasOwnProperty("title") ? property["title"] : key}
                                    margin="normal"
                                    fullWidth
                                    required={(property["type"] === "null" || (Array.isArray(property["type"]) && property["type"].includes("null"))) ? false : true}
                                    error={backendSettingsErrorMessages.hasOwnProperty(key)}
                                    helperText={backendSettingsErrorMessages.hasOwnProperty(key) ? backendSettingsErrorMessages[key] : ""}
                                    sx={{mb: 1}}
                                    value={backendSettings[key]}
                                    onChange={(e) => handleBackendSettingsChange(e, key)}
                                  />
                                }
                              </React.Fragment>
                            )
                          })
                        }
                   </div>
                    }
                </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent: "center", mb: 1}}>
                <Button onClick={(e) => handleStepChange(step-1)} disabled={step===0 || isSaving} startIcon={<NavigateBeforeIcon/>} sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} color={"primary"} variant={"outlined"} autoFocus>
                  Prev
                </Button>
                {step !== 2 &&
                    <Button disabled={(step === 0 && jobName === "") || (step === 1 && code === "")} onClick={(e) => handleStepChange(step+1)} endIcon={<NavigateNextIcon/>} sx={(theme) => ({width: theme.customSizes.dialogButtonWidth, mr:2})} color={"primary"} variant={"outlined"} autoFocus>
                        Next
                    </Button>
                }
                {step === 2 && isSaving &&
                    <CircularProgress sx={{ml: 2, mr: 1}}/>
                }
                {step === 2 && !isSaving &&
                    <Button disabled={language === Language.OpenQASM2 && shots === ""} type={"submit"} form="add_job_form" onClick={(e) => handleSubmit(e)} sx={(theme) => ({width: theme.customSizes.dialogButtonWidth, mr:2})} style={{boxShadow: "none"}} color={"primary"} variant={"contained"} autoFocus>
                        Submit
                    </Button>
                }
                <Button sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} color={"error"} variant={"outlined"} autoFocus onClick={props.handleClose}>
                  Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
