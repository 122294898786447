import React, {useContext, useEffect, useState} from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import EditIcon from '@mui/icons-material/Edit';
import PageLayout from '../../components/PageLayout';
import UserModal from './UserModal';
import {User, Modal, ModalType, PageType, RequestData, ContentType} from '../../components/models';
import { AppContext } from '../../components/AppContext';
import { fetchWrapper } from '../../components/utilities';

const columns: GridColDef[] = [
    { field: 'user_id', headerName: 'User ID', width: 90},
    { field: 'username', headerName: 'Username', minWidth: 200, flex: 1},
    { field: 'full_name', headerName: 'Full Name', minWidth: 200, flex: 1},
    { field: 'role_name', headerName: 'Role', width: 140},
    // { field: 'hashed_password', headerName: 'Hashed Password'},
    { field: 'is_active', headerName: 'Is Active', width: 90},
    { field: 'role_id', headerName: 'Role ID', width: 90},
  ];


export default function ManageUsers() {

    const appContext = useContext(AppContext);
    const [users, setUsers] = useState([] as User[]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [selectedUser, setSelectedUser] = useState(undefined as undefined | User);
    const [modal, setModal] = useState({isOpen: false, type: ModalType.Edit} as Modal);

    function onSuccessfullUserLoading(data: any) {
        data.map((row: any) => {
            row['id'] = row.user_id
        });

        //Your own account details can only be edited using the account settings page.
        let filteredData = data.filter((row: any) => row['username'] !== appContext.userData?.username);
        setUsers(filteredData);
    }

    function getUsers(fullName: string){

        //"admin/users/?full_name="+fullName

        setIsLoading(true);

        let requestData: RequestData = {
            url: "admin/users/?full_name="+fullName, method: "GET", contentType: ContentType.urlencoded, 
            body: undefined
        }
    
        fetchWrapper(
            requestData,
            onSuccessfullUserLoading,
            appContext,
            () => setIsLoading(false)
        );
    }

    useEffect(() => {
        if(!modal.isOpen) {
            getUsers(searchInput);
        }
    },[modal]);

    function onKeyDown(e: any) {
        if(e.keyCode === 13){
            getUsers(searchInput);
         }
    }

    function handleChange(e: any) {
        setSearchInput(e.target.value);
    }

    function onRowClick(e: any) {
        setSelectedUser(e.row);
    }

    function onClickButton(type: ModalType) {
        setModal({isOpen: true, type: type});
    }

    return (
        <PageLayout pageType={PageType.ManageUsers}>
            <Box sx={{display: "flex", flexWrap: "wrap"}}>
                {modal.isOpen &&
                    <UserModal initialUserData={modal.type === ModalType.Create? undefined : selectedUser} modal={modal} handleClose={() => setModal({...modal, isOpen: false})}/>
                }
                <TextField
                  sx={{alignSelf: "center", mr: 1, ml: 0, mb: 0, mt: 1}}
                  onChange={(e) => handleChange(e)}
                  onBlur={() => getUsers(searchInput)}
                  onKeyDown={(e) => onKeyDown(e)}
                  size="small"
                  margin="normal"
                  name="Full Name"
                  label="Full Name"
                  type="Full Name"
                  id="Full Name"
                  InputProps={{endAdornment: <IconButton sx={{paddingRight: 0}} onClick={() => getUsers(searchInput)}><SearchIcon color={"primary"}/></IconButton>}}
                  //autoComplete="current-password"
                />
                <Button
                    sx={(theme) => ({height: theme.customSizes.buttonHeight, alignSelf: "center",  mr: 1, ml: 0, mb: 0, mt: 1})}
                    type="button"
                    size={"small"}
                    variant="outlined"
                    color={"primary"}
                    disabled={selectedUser === undefined}
                    startIcon={<EditIcon />}
                    onClick={(e) => onClickButton(ModalType.Edit)}
                >
                    Edit User
                </Button>
                <Button
                    sx={(theme) => ({height: theme.customSizes.buttonHeight, mb: 0, mt: 1, alignSelf: "center"})}
                    size="small"
                    type="button"
                    variant="outlined"
                    color={"primary"}
                    startIcon={<PersonAddAltIcon />}
                    onClick={(e) => onClickButton(ModalType.Create)}
                >
                    Add User
                </Button>
            </Box>

            <DataGrid
                sx={{"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                    {
                      outline: "none !important",
                    }, mt: 1}}
                loading={isLoading}
                rows={users}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[5]}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                onRowClick={(e) => onRowClick(e)}
            /> 
        </PageLayout>
    )
}