import {PageBase, StorageKeys, UserBase} from '../../components/models';

export function getIsDarkMode() {
    const theme = localStorage.getItem(StorageKeys.Theme)
    if (theme) {
      return (theme === StorageKeys.Dark);
    } else {
      localStorage.setItem(StorageKeys.Theme, StorageKeys.Light);
      return false;
    }
  }

export function getPages(): PageBase[] {
    const pages = localStorage.getItem(StorageKeys.Pages);
    if(pages){
      let parsedPages = JSON.parse(pages);
      return [...parsedPages];
    }
    return []
  }

export function getPageIDNameDict(): {[type: number] : string} {
    let pagesTemp = getPages();
    let pageIDNameDictTemp = {} as {[type: number] : string};
    pagesTemp.map((page: PageBase) => {
        pageIDNameDictTemp[page.pageType] = page.name;
    });
    return {...pageIDNameDictTemp}
  }

export function getUserData(): UserBase | undefined {
    const userData = localStorage.getItem(StorageKeys.UserData);
    if(userData){
      return JSON.parse(userData);
    }
    return undefined
}