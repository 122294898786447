import { createTheme } from '@mui/material/styles';

//CUSTOM THEME VARIABLES
declare module '@mui/material/styles' {
    interface Theme {
      customSpacing: {
        goldenMeanSmall: string;
        goldenMeanLarge: string;
      },
      customSizes: {
        standardTextfield: string;
        iconSmall: string;
        iconLarge: string;
        dialogButtonWidth: string;
        buttonHeight: string;
        statusBatchWidth: string;
      },
      customBackground: {
        highlight: string
      },
      customColors: {
        primaryOnHighlightBackground: string;
      },
      customTextfieldLength: {
        standardTextfieldLength: number
      },
      regex: {
        strongPassword: RegExp,
        onlyText: RegExp,
        email: RegExp
      }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
      customSpacing?: {
        goldenMeanSmall?: string;
        goldenMeanLarge?: string;
      },
      customSizes?: {
        standardTextfield?: string;
        dialogButtonWidth?: string;
        iconSmall?: string;
        iconLarge?: string;
        buttonHeight?: string;
        statusBatchWidth?: string;
      },
      customBackground?: {
        highlight?: string
      },
      customColors?: {
        primaryOnHighlightBackground?: string
      },
      customTextfieldLength?: {
        standardTextfieldLength?: number
      },
      regex?: {
        strongPassword?: RegExp,
        onlyText?: RegExp,
        email?: RegExp
      }
    }
  }
  
//OVERIDING THE DEFAULT THEME
const baseTheme = createTheme({
    // shadows: Array(25).fill("none") as any,
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 3
                }
            }
        },
        MuiDialog: {
          styleOverrides: {
            root: {
              '& .MuiDialog-paper': {
                backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
              }
            }
          }
        }
    },
    typography: {
        fontFamily: [
          'Inter',
          // '-apple-system',
          // 'BlinkMacSystemFont',
          // '"Segoe UI"',
          // 'Roboto',
          // '"Helvetica Neue"',
          // 'Arial',
          'sans-serif',
          // '"Apple Color Emoji"',
          // '"Segoe UI Emoji"',
          // '"Segoe UI Symbol"',
        ].join(','),
      },
    customSpacing: {
      goldenMeanSmall: "38.2%",
      goldenMeanLarge: "61.8%"
    },
    customSizes: {
      standardTextfield: "320px",
      iconSmall: "24px",
      iconLarge: "200px",
      dialogButtonWidth: "100px",
      buttonHeight: "40px",
      statusBatchWidth: "140px"
    },
    customTextfieldLength: {
      standardTextfieldLength: 50
    },
    regex: {
      //strongPassword: /^.{6,}$/,
      strongPassword: /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/])(?=.*[0-9])(?=.*[a-z]).{8,}$/, //At least one uppercase, one lowercase, one special character and at least 8 characters.
      onlyText: /^[a-zA-Z]+$/,
      email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    }
})

const lightTheme = createTheme({
    ...baseTheme,
    palette: {
      mode: 'light',
      primary: {        
          light: '#93cbe4',
          main: '#0d7aae',
          //dark: 'rgb'   rgb(8 82 117) oder so? andere button Farbe bei highlight Hintergrund!
      },
      text: {
        primary: 'rgb(27 36 41)',
        secondary: "rgb(68 89 101)"
      }
    },
    customBackground: {
      highlight: "#93cbe4"
    },
    customColors: {
      primaryOnHighlightBackground: "rgb(9 85 121)"
    }
})
  
const darkTheme = createTheme({
    ...baseTheme,
    palette: {
      mode: 'dark',
      primary: {        
          //light: '#93cbe4',
          main: '#93cbe4',
          dark: "#0d7aae"
      },
      background: {
          default: "rgb(22,29,33)",
          paper: "rgb(17,22,25)"
      }
    },
    customBackground: {
      highlight: "rgb(22,29,33)"
    },
    customColors: {
      primaryOnHighlightBackground: "#93cbe4"
    }
})

export { darkTheme, lightTheme }