import React, {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {User, Role, Page, PageType, Modal, UserModalType, ModalType, RequestData, ContentType, Group } from '../../components/models';
import { Box, IconButton, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import HelpIcon from '@mui/icons-material/Help';
import CircularProgress from '@mui/material/CircularProgress';
import { passwordTooWeakText, passwordEditHelperText } from '../../components/texts';
import { AppContext } from '../../components/AppContext';
import { fetchWrapper } from '../../components/utilities';

//const passwordTooWeakText: string = "A password must contain more than 7 characters including at least one upper case letter, one lower case letter, one numeral and one special character (!@#$&*).";

interface Validation {
  passwordIsValid: boolean,
  fullnameIsValid: boolean,
  usernameIsValid: boolean
}

interface Props {
    handleClose: any,
    initialUserData: User | undefined,
    modal: Modal,
}

export default function UserModal(props: Props) {

  const appContext = useContext(AppContext);
  const [validation, setValidation] = useState({passwordIsValid: true, fullnameIsValid: true, usernameIsValid: true} as Validation);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const [pages, setPages] = useState([] as Page[]);
  const [groups, setGroups] = useState([] as Group[]);

  const [user, setUser] = useState({
      user_id: props.initialUserData?.user_id,
      username: props.initialUserData?.username,
      full_name: props.initialUserData?.full_name,
      password: undefined,
      role: props.initialUserData === undefined? Role[Role.Developer] : props.initialUserData.role_name,
      is_active: props.modal.type === ModalType.Create? true: props.initialUserData?.is_active,
      page_ids: [] as number[]
  } as UserModalType);

  const fieldStyle = (theme: any) => ({
      width: theme.customSizes.standardTextfield,
      mb: 3,
      justifyContent: "center"
  })

  const fieldStyle2 = (theme: any) => ({
    width: theme.customSizes.standardTextfield,
    mb: 1,
    justifyContent: "center"
  })

  function onSuccessfullPropertiesLoading(data: any) {
    let pagesDataTemp = data["pages"];
    let groupsDataTemp = data["groups"];

    let pagesData = [] as Page[];
    for(let i = 0; i < pagesDataTemp.length; i++){
      if(pagesDataTemp[i].page_id in PageType){
        pagesData.push({name: pagesDataTemp[i].name, pageType: pagesDataTemp[i].page_id as PageType, isActive: pagesDataTemp[i].is_active === '1'? true : false} as Page);
      }
    }

    let groupsData = [] as Group[];
    for(let i = 0; i < groupsDataTemp.length; i++){
      groupsData.push({groupID: groupsDataTemp[i].group_id, name: groupsDataTemp[i].name, isMember: groupsDataTemp[i].is_member === '1'? true : false} as Group);
    }

    setPages([...pagesData]);
    setGroups([...groupsData]);
  }

  function getProperties(userID: number | undefined){
    setIsLoadingProperties(true);

    let url =  "admin/users/properties/";
    if(userID !== undefined){
      url += "?user_id="+userID;
    }
    let requestData: RequestData = {
      url: url, method: "GET", contentType: ContentType.urlencoded, 
      body: undefined
    }

    fetchWrapper(
      requestData,
      onSuccessfullPropertiesLoading,
      appContext,
      () => setIsLoadingProperties(false)
    );
  }

  useEffect(() => {
      getProperties(props.initialUserData?.user_id);
  },[props.modal, props.initialUserData]);

  function handlePasswordChange(e: any) {
    if(e.target.value === ""){
      if(props.modal.type === ModalType.Edit){
        setValidation({...validation, passwordIsValid: true});
      }
      setUser({...user, password: undefined});
    } else {
      setUser({...user, password: e.target.value});
    }
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let newUserData = {...user};

    //In case the data is undefined:
    if(newUserData.full_name === undefined || newUserData.username === undefined || (props.modal.type === ModalType.Create && newUserData.password === undefined)){
      //We should never arrive here, because of the required property of the textfields.
      return
    }

    //Validate fields
    let fullNameIsValid = true;theme.regex.onlyText.test(newUserData.full_name);
    let emailIsValid = true;
    if(props.modal.type === ModalType.Create && newUserData.role !== Role[Role.QuantumDevice]){
      emailIsValid = theme.regex.email.test(newUserData.username);
    }
    let passwordIsValid = true;
    if(newUserData.password !== undefined){
      passwordIsValid = theme.regex.strongPassword.test(newUserData.password);
    }

    setValidation({usernameIsValid: emailIsValid, passwordIsValid: passwordIsValid, fullnameIsValid: fullNameIsValid})
    if(!emailIsValid || !fullNameIsValid || !passwordIsValid){
      return
    }

    setIsSaving(true);
    newUserData.page_ids = pages.filter(element => element.isActive).map(element => element.pageType as number);
    newUserData.group_ids = groups.filter(element => element.isMember).map(element => element.groupID);
    
    let requestData: RequestData = {
      url: "admin/users/", method: props.modal.type === ModalType.Create? 'POST' : 'PUT', contentType: ContentType.json, 
      body: JSON.stringify(newUserData)
    };

    fetchWrapper(
      requestData,
      () => props.handleClose(),
      appContext,
      () => setIsSaving(false)
    );
  }

  return (
      <Dialog
        PaperProps={{
          style: {backgroundColor: theme.palette.background.paper}
        }}
        fullScreen={fullScreen}
        open={props.modal.isOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.handleClose();
          }
        }}
        aria-labelledby={props.modal.type === ModalType.Create? "Create User" : "Edit User"}
        onBackdropClick={undefined}
      >
        <DialogTitle id="responsive-dialog-title">
          {props.modal.type === ModalType.Create? "Create User" : "Edit User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={"form"} id="updateUserForm" onSubmit={handleSubmit}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", ml:6, mr:6}}>
              <Typography sx={(theme) => ({width: theme.customSizes.standardTextfield, mb: 3})} variant={"subtitle1"} color={"text.primary"}>{"General user data"}</Typography>
              <TextField 
                  name="username" 
                  required
                  error={!validation.usernameIsValid}
                  helperText={validation.usernameIsValid? "": "Please enter a valid email address."} 
                  sx={fieldStyle} 
                  label="Username (email)" 
                  disabled={props.modal.type !== ModalType.Create} 
                  value={user.username} 
                  inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
                  onChange={(e) => setUser({...user, username: e.target.value})}
              />
              <TextField 
                  name={"full_name"} 
                  error={!validation.fullnameIsValid} 
                  helperText={validation.fullnameIsValid? "" : "Only letters are allowed in this field."}
                  required 
                  sx={fieldStyle} 
                  label={"Full Name"} 
                  value={user.full_name} 
                  inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}} 
                  onChange={(e) => setUser({...user, full_name: e.target.value})}
              />
              <TextField
                  required
                  value={user.role}
                  sx={fieldStyle}
                  select
                  label="Role"
                  name={"role"}
                  onChange={(e) => setUser({...user, role: e.target.value})}
                >
                  {Object.values(Role).map((roleName) => 
                    typeof roleName === "string" &&
                      <MenuItem key={roleName} value={roleName}>
                        {roleName}
                      </MenuItem>
                    
                  )}
              </TextField>
              <TextField 
                required={props.modal.type === ModalType.Create? true: false}
                name={"password"} 
                sx={fieldStyle2} 
                label={"Password"} 
                type="password"
                error={!validation.passwordIsValid} 
                value={user.password === undefined? "": user.password} 
                helperText={!validation.passwordIsValid? passwordTooWeakText : (props.modal.type === ModalType.Edit? passwordEditHelperText: "")} 
                onChange={handlePasswordChange} 
                inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
              />
              <Box sx={(theme: any) => ({width: theme.customSizes.standardTextfield,display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", mb: 3})}>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <Box sx={{mr: 1}}>Is Active</Box>
                    <Tooltip title="The user cannot access any cloud services, if this is unchecked.">
                     <IconButton>
                      <HelpIcon fontSize='small' />
                    </IconButton>
                    </Tooltip>
                </Box>
                <Checkbox checked={user.is_active} onChange={(e) => setUser({...user, is_active: !user.is_active})}/>
              </Box>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", ml:6, mr:6}}>
              <Typography sx={(theme) => ({width: theme.customSizes.standardTextfield, mb: 1})} variant="subtitle1" color={"text.primary"}>{"Pages of webapp"}</Typography>
              {isLoadingProperties &&
                <Box sx={(theme: any) => ({width: theme.customSizes.standardTextfield,display: "flex", mt: 2, flexDirection: "row", justifyContent: "center"})}>
                  <CircularProgress/>
                </Box>
              }
              {pages.map((page) => (
                <Box key={"page_"+page.pageType.toString()} sx={(theme: any) => ({width: theme.customSizes.standardTextfield,display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", ml: 2, mr: 2})}>
                  <Box>{page.name}</Box>
                  <Checkbox checked={page.isActive} onChange={(e) => setPages(pages.map(element => element.pageType === page.pageType ? {...element, isActive : !element.isActive} : element))}/>
                </Box>
              ))}
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", ml:6, mr:6, mt: 2}}>
              <Typography sx={(theme) => ({width: theme.customSizes.standardTextfield, mb: 1})} variant="subtitle1" color={"text.primary"}>{"Groups"}</Typography>
              {isLoadingProperties &&
                <Box sx={(theme: any) => ({width: theme.customSizes.standardTextfield,display: "flex", mt: 2, flexDirection: "row", justifyContent: "center"})}>
                  <CircularProgress/>
                </Box>
              }
              {groups.map((group) => (
                <Box key={"group_"+group.groupID.toString()} sx={(theme: any) => ({width: theme.customSizes.standardTextfield,display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", ml: 2, mr: 2})}>
                  <Box>{group.name}</Box>
                  <Checkbox checked={group.isMember} onChange={(e) => setGroups(groups.map(element => element.groupID === group.groupID ? {...element, isMember : !element.isMember} : element))}/>
                </Box>
              ))}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "center", mb: 1}}>
          {isSaving &&
            <CircularProgress/>
          }
          {!isSaving &&
            <React.Fragment>
              <Button type="submit" form="updateUserForm" sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} color={"primary"} variant={"outlined"} autoFocus>
                Save
              </Button>
              <Button sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} color={"error"} variant={"outlined"} autoFocus onClick={props.handleClose}>
                Cancel
              </Button>
            </React.Fragment>
          }
        </DialogActions>
      </Dialog>
  );
}
