import { Box, Typography} from "@mui/material";
import React from "react";
import { useTheme } from '@mui/material/styles';
import {TextLanguage} from './models';
import PublicIcon from '@mui/icons-material/Public';
import { Logout } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

interface Props {
    language: TextLanguage,
    url: string
}

export default function Logo(props: Props) {

    const theme = useTheme();
    const navigate = useNavigate();

    function onClickLanguage(){
        if(props.language === TextLanguage.German){
            navigate(props.url + "/en");
        } else {
            navigate(props.url + "/de");
        }

    }

    return (
        <Box onClick={onClickLanguage} sx={{position: "absolute", right: "12px", top: "16px", display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}}>
            <PublicIcon color={"action"} fontSize="small"/>
            <Typography sx={{ml: 0.75, fontSize: 12}} color={"text.primary"}>{props.language === TextLanguage.German? TextLanguage.English: TextLanguage.German}</Typography>
        </Box>
    )
}
