import { Box } from "@mui/material";
import React, {useContext} from "react";
import IonLoader, { Ion } from "../../components/IonLoader";
import { Backend, BackendStatusName, Role } from "../../components/models";
import { useTheme } from '@mui/material/styles';
import { AppContext } from "../../components/AppContext";

interface Props {
    backends: Backend[]
}

function validateBackendSettings(backendSettings: any){
    if(backendSettings !== null && backendSettings.hasOwnProperty("properties")){
        return true;
    } else {
        return false;
    }
}

function renderBackendSetting(backendSettings: any) {
    let settingsAreValid = validateBackendSettings(backendSettings);

    if(settingsAreValid){
        return (
            <table>
                <thead>
                    <tr>
                        <th style={{textAlign: "left"}}>Name</th>
                        <th style={{textAlign: "left"}}>Key</th>
                        <th style={{textAlign: "left"}}>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(backendSettings["properties"]).map((key, index) => {

                        let title = backendSettings["properties"][key]["title"] || key;
                        let description = backendSettings["properties"][key]["description"] || "";
                        let defaultValue = backendSettings["properties"][key]["default"] || "";
                        let defaultString = defaultValue.toString() === "" ? "" : `default value: ${defaultValue.toString()}`;
                        let types = backendSettings["properties"][key]["type"] || "";
                        let typeString = types.toString() === "" ? "" : `Type: ${types.toString()}; `;

                        let descriptionString = `(${typeString}${defaultString}) - ${description}.`

                        return (
                            <tr key={index}>
                                <td style={{textAlign: "left"}}>{title}</td>
                                <td style={{textAlign: "left"}}>{key}</td>
                                <td style={{textAlign: "left"}}>{descriptionString}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    } else {
        return (
            <React.Fragment/>
        )
    }
}

export default function BackendsRenderer(props: Props){

    const theme = useTheme();
    const appContext = useContext(AppContext);

    function renderStatus(backend: Backend) {

        //Admin colors
        let color = "";
        if(backend.is_online && backend.status_name === BackendStatusName[BackendStatusName.Idle]){
            color = theme.palette.success.light
        } else if (backend.is_online && (backend.status_name === BackendStatusName[BackendStatusName.Executing] || backend.status_name === BackendStatusName[BackendStatusName.Calibrating])) {
            color = theme.palette.warning.light;
        } else {
            color = theme.palette.error.main;
        }

        //Admin status names
        let status_name = "Offline"
        if(backend.is_online) {
            status_name = backend.status_name as string;
        }

        //For Developers the unresponsive status should look like an Offline backend.
        if(appContext.userData?.role_id === Role.Developer && backend.is_online && backend.status_name === BackendStatusName[BackendStatusName.Unresponsive]){
            color = theme.palette.error.main;
            status_name = "Offline";
        }

        return (
            <Box sx={{display: "flex", alignItems: "center"}}>
                {backend.is_online && backend.status_name === BackendStatusName[BackendStatusName.Executing] &&
                    <IonLoader color={color}></IonLoader>
                }
                {(!backend.is_online || backend.status_name !== BackendStatusName[BackendStatusName.Executing])&&
                    <Ion color={color}/>
                }
                <Box sx={{ml: 1}}>{status_name}</Box>
            </Box>
        )
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            {props.backends.map((backend: Backend) => 
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <h3>{backend.username}</h3>
                    <div>{backend.description}</div>
                    <Box sx={{mb: 1, mt: 2}}>Specifications:</Box>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{textAlign: "left"}}>Status</td>
                                <td style={{textAlign: "right"}}>{renderStatus(backend)}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "left"}}>Type</td>
                                <td style={{textAlign: "right"}}>{backend.simulator? "Simulator": "Quantum computer"}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "left"}}>Max number of qubits</td>
                                <td style={{textAlign: "right"}}>{backend.max_n_qubits}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "left"}}>Max number of shots</td>
                                <td style={{textAlign: "right"}}>{backend.max_shots}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "left"}}>Native gates</td>
                                <td style={{textAlign: "right"}}>{backend.basis_gates.toString()}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "left"}}>Location</td>
                                <td style={{textAlign: "right"}}>{backend.local? "Local": "Cloud"}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "left"}}>Hybrid job support</td>
                                <td style={{textAlign: "right"}}>{backend.supports_hybrid_jobs? "Yes": "No"}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: "left"}}>Max total computation time (s)</td>
                                <td style={{textAlign: "right"}}>{backend.max_total_computation_time_seconds}</td>
                            </tr>
                            {backend.supports_hybrid_jobs &&
                                <React.Fragment>
                                    <tr>
                                        <td style={{textAlign: "left"}}>Max intermediate classical computation time (s)</td>
                                        <td style={{textAlign: "right"}}>{backend.max_intermediate_classical_computation_time_seconds}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: "left"}}>Max intermediate quantum computation time (s)</td>
                                        <td style={{textAlign: "right"}}>{backend.max_intermediate_quantum_computation_time_seconds}</td>
                                    </tr>
                                </React.Fragment>
                            }
                        </tbody>
                    </table>
                    {backend.user_settings_schema !== null &&
                        <React.Fragment>
                            <Box sx={{mb: 1, mt: 1}}>Settings:</Box>
                            {renderBackendSetting(backend.user_settings_schema)}
                        </React.Fragment>
                    }
                </Box>
            )}
        </Box>
    )
}