import { Box, Button, IconButton, Typography } from "@mui/material";
import React, {useState, useCallback, useRef, useContext} from "react";
import { ContentType, Page, PageType, RequestData } from "../../components/models";
import PageLayout from "../../components/PageLayout";
import {Ion, Area, Operation} from "./models";
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IonChip from "./ionChip";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchWrapper } from "../../components/utilities";
import { AppContext } from "../../components/AppContext";

const saveSvgAsPng = require('save-svg-as-png')

const CustomTextArea = styled('textarea')`
    background-color: ${props => props.theme.palette.background.paper};
    color: ${props => props.theme.palette.text.secondary};
    width: ${props => props.theme.customSizes.standardTextfield};
    height: 75vmin;
    padding: ${props => props.theme.spacing(2)};
    margin-bottom: ${props => props.theme.spacing(2)};
    resize: none;
    box-sizing: border-box;
    border-color: ${props => props.theme.palette.divider};
    &:focus {
      outline: none !important;
      border-color: ${props => props.theme.palette.primary.main};
      box-shadow: 0 0 1px ${props => props.theme.palette.primary.main};
    }
`

// Works but baseline style was not accepted in svg editor. Only browser...
// function downloadBlob(blob: any, filename: string) {
//   const objectUrl = URL.createObjectURL(blob);
//   const link = document.createElement("a");
//   link.href = objectUrl;
//   link.download = filename;
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
//   setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
// }

const apiUrl = process.env.REACT_APP_API_URL as string
const chipLength: number = 1000;
const chipCutoffY: number = 0;
const padding: number = 20;
let REGISTER_SIZE: number = 0;
const red = "rgb(199,56,52)"; //TODO: Get this from the theme.

export default function CompilerVisualization() {

    const [openQASMText, setOpenQASMText] = useState(
`OPENQASM 2.0;
include "qelib1.inc";
qreg q[2];
creg c[2];
h q[0];
cx q[0],q[1];
barrier q[0],q[1];
measure q[0] -> c[0];
measure q[1] -> c[1];`
          );
    //const [tiasmText, setTiasmText] = useState([] as string[]);
    const theme = useTheme();
    const appContext = useContext(AppContext);
    const [tiasmJson, setTiasmJson] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ionSpacing, setIonSpacing] = useState(0);
    const [graphViewBox, ] = useState("0 " + (chipCutoffY/2).toString() + " " +chipLength.toString()+" "+(chipLength-chipCutoffY).toString());
    const [tiasmIndex, setTiasmIndex] = useState(-1);
    const [ionStacks, setIonStacks] = useState({"0": [], "1": [], "2": [], "3": []} as {[area: string]: Ion[]});
    const [commentHistory, setCommentHistory] = useState([] as string[]);
    const [ionToPartnersMap, setIonToPartnersMap] = useState({} as {[ionID: string]: string[]});
    const [ionToPartnerIndexMap, setIonToPartnerIndexMap] = useState({} as {[ionID: string]: number});
    
    // parent div of svg has to have ref={svgRef}
    // const svgRef = useRef(null);
    // const downloadSVG = useCallback(() => {
    //     const temp = svgRef.current as any;
    //     const svg = temp.innerHTML;
    //     const blob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
    //     downloadBlob(blob, `myimage.svg`);
    // }, []);
    function downloadSVG() {
      let imageOptions = {
        scale: 1,
        encoderOptions: 1,
        backgroundColor: theme.palette.background.paper
      }
      saveSvgAsPng.saveSvgAsPng(document.getElementById('background'), 'ionchip.png', imageOptions);
    };

    function onClickCompile() {
        compile();
    }

    function onClickPrevious() {
        if(tiasmIndex > -1) {
          let newTiasmIndex = tiasmIndex - 1
          applyTiasmInstruction(tiasmJson[tiasmIndex], true);
          setTiasmIndex(newTiasmIndex);
        }
    }
    
    function onClickNext() {
        if(tiasmIndex < tiasmJson.length - 1) {
    
          let newTiasmIndex = tiasmIndex + 1
          setTiasmIndex(newTiasmIndex);
          applyTiasmInstruction(tiasmJson[newTiasmIndex], false);
        }
    }

    function onSuccessfullCompile(data: any) {

      let correctedData = data.replaceAll("\n", "").replaceAll(",,", ",");
      let tiasmJsonTemp = JSON.parse(correctedData); 

      preprocesTiasm(tiasmJsonTemp);
      setTiasmJson(tiasmJsonTemp["data"]); //List of instructions was moved to tiasm["data"]
      
      let initial_ion_order_json = tiasmJsonTemp["initial_ion_order"];
      let ionStacksTemp = {"0": ionIndexListToIonList(initial_ion_order_json["storage"]), 
                           "1": ionIndexListToIonList(initial_ion_order_json["temp_storage"]), 
                           "2": ionIndexListToIonList(initial_ion_order_json["compute"]), 
                           "3": ionIndexListToIonList(initial_ion_order_json["spam"])} as {[area: string]: Ion[]};

      setIonStacks(ionStacksTemp);

      let total_num_ions = ionStacksTemp["0"].length + ionStacksTemp["1"].length + ionStacksTemp["2"].length + ionStacksTemp["3"].length;

      setIonSpacing(getIonSpacing(total_num_ions));
      REGISTER_SIZE = total_num_ions;
    }

    function compile() {
        setIsLoading(true);
        setTiasmIndex(-1);
        setIonStacks({"0": [], "1": [], "2": [], "3": []} as {[area: string]: Ion[]});
        setIonToPartnerIndexMap({} as {[ionID: string]: number});
        setIonToPartnersMap({} as {[ionID: string]: string[]});
    
        //let url = "http://0.0.0.0:8100/compiler/openqasm2/to/tiasm/"
        //let url = "http://127.0.0.1:8000/compiler/openqasm2/to/tiasm/";
    
        /**************** 
         HACK TO SUPPORT TIASM JSON INPUT.
        *****************/
        try{
          onSuccessfullCompile(openQASMText);
          setIsLoading(false);
          // let correctedData = openQASMText.replaceAll("\n", "").replaceAll(",,", ",");
          // let tiasmJsonTemp = JSON.parse(correctedData); 
          // preprocesTiasm(tiasmJsonTemp);
          // setTiasmJson(tiasmJsonTemp);
            
          // setTiasmIndex(0);
          // setCommentHistory([] as string[]);
          // setIonStacks({"0": [], "1": [], "2": [], "3": []} as {[area: string]: Ion[]});
          // applyTiasmInstruction(tiasmJsonTemp[0], false);
          // setIsLoading(false);
    
          //If parsing the input as json succeded we reach this point -> done
          //If it failed, the input is not TIASM/JSON 
          return;
        } catch(e){
          console.log(e);
        }
        /***************** */

        let requestData: RequestData = {
            url: "compiler/openqasm2/to/tiasm/", method: "POST", contentType: ContentType.json, 
            body: JSON.stringify({'language_name': 'OpenQASM2', 'text': openQASMText})
        }

        fetchWrapper(
          requestData,
          onSuccessfullCompile,
          appContext,
          () => setIsLoading(false)
        );

    
        // fetch(apiUrl + "compiler/openqasm2/to/tiasm/",{
        //   method: 'POST',
        //   headers: {"content-type": "application/json", "Authorization": "Bearer " + props.token},
        //   body: JSON.stringify({'language_name': 'OpenQASM2', 'text': openQASMText})
        // })
        //   .then(res => res.json())
        //   .then((data) => {
        //     let correctedData = data.replaceAll("\n", "").replaceAll(",,", ",");
        //     let tiasmJsonTemp = JSON.parse(correctedData); 
        //     preprocesTiasm(tiasmJsonTemp);
        //     setTiasmJson(tiasmJsonTemp);
            
        //     setIsLoading(false);
        //     setTiasmIndex(0);
        //     setIonStacks({"0": [], "1": [], "2": [], "3": []} as {[area: string]: Ion[]});
        //     applyTiasmInstruction(tiasmJsonTemp[0], false);
    
        //   })
        //   .catch((e) => {
        //       setIsLoading(false);
        //   })
    }

    function getIonSpacing(register_size: number) {
        if(register_size > 0){
          return (chipLength / 2 - 2 * padding) / (register_size);
        } else {
          return 0
        }
    } 

    function arrangeIons(ions: Ion[], area: Area): Ion[] {

        let new_ions = [] as Ion[];
    
        if(area === Area.Storage){
          for(let i: number = 0; i < ions.length; i++) {
            let ion = {x:0, y:0, label:ions[i].label, operationHistory: ions[i].operationHistory, area: area};
            ion.x = padding + (REGISTER_SIZE - ions.length + i + 1/2) * ionSpacing;
            ion.y = chipLength / 2;
            new_ions.push(ion);
          }
        }
        if(area === Area.Tempstorage) {
          for(let i: number = 0; i < ions.length; i++) {
            let ion = {x:0, y:0, label:ions[i].label, operationHistory: ions[i].operationHistory, area: area};
            ion.x = chipLength / 2 + padding + (ions.length - 1 - i + 1/2) * ionSpacing;
            ion.y = chipLength / 2;
            new_ions.push(ion);
          }
        }
        if(area === Area.Spam && ions.length === 1) {
          let ion = {x:0, y:0, label:ions[0].label, operationHistory: ions[0].operationHistory, area: area};
          ion.x = chipLength / 2;
          ion.y = chipLength / 4;
          new_ions.push(ion);
        }
        if(area === Area.Compute && ions.length < 3) {
          if (ions.length === 1) {
            let ion = {x:0, y:0, label:ions[0].label, operationHistory: ions[0].operationHistory, area: area};
            ion.x = chipLength / 2;
            ion.y = 3 * chipLength / 4;
            new_ions.push(ion);
          }
          if (ions.length === 2) {
            let ion_0 = {x:0, y:0, label:ions[0].label, operationHistory: ions[0].operationHistory, area: area};
            ion_0.x = chipLength / 2;
            ion_0.y = 3 * chipLength / 4 + ionSpacing / 2;
            new_ions.push(ion_0);
    
            let ion_1 = {x:0, y:0, label:ions[1].label, operationHistory: ions[1].operationHistory, area: area};
            ion_1.x = chipLength / 2;
            ion_1.y = 3 * chipLength / 4 - ionSpacing / 2;
            new_ions.push(ion_1);
          }
        }
        return new_ions;
    }

    function applyTiasmInstruction(instruction: any, reversed: boolean) {
        let newComment = "";
        if("comment" in instruction){
          newComment = instruction["comment"];
        }
        if(reversed) {
          let commentHistoryTemp = [...commentHistory];
          commentHistoryTemp.pop();
          setCommentHistory([...commentHistoryTemp]);
        } else {
          setCommentHistory([...commentHistory, newComment]);
        }
    
        /*if(instruction['type'] === "meta_data") {
          if(reversed) {
            setInitialIonOrder([]);
          } else {
            let ion_order = [...instruction["initial_ion_order"]];
            ion_order = ion_order.reverse();
            setInitialIonOrder([...ion_order]);
          }
        }
        if(instruction['type'] === "quantum_register") {
          if(reversed) {
            setIonStacks({"0": [], "1": [], "2": [], "3": []});
          } else {
            let size = instruction["size"];
    
            setIonStacks({"0": [...getStartConfig(size, [...initialIonOrder])], "1": [], "2": [], "3": []});
    
            setIonSpacing(getIonSpacing(size));
            REGISTER_SIZE = size;
          }
        }*/
        if(instruction['type'] === "measure") {
          let newSpamIons: Ion[] = [];
          if(reversed) {
            for(let i: number = 0; i < ionStacks[Area.Spam.toString()].length; i++) {
              let adjustedIon = {...ionStacks[Area.Spam.toString()][i]};
              adjustedIon.operationHistory.pop();
              newSpamIons.push(adjustedIon);
            }
          } else {
            for(let i: number = 0; i < ionStacks[Area.Spam.toString()].length; i++) {
              let adjustedIon = {...ionStacks[Area.Spam.toString()][i]};
              adjustedIon.operationHistory.push(Operation.Measure);
              newSpamIons.push(adjustedIon);
            }
          }
          setIonStacks({
            ...ionStacks,
            [Area.Spam.toString()]: [...newSpamIons]
          })
        }
        if(instruction['type'] === "gate"){
    
          let gate = instruction["op"];
          let newComputeIons: Ion[] = [];
    
          if(reversed) {
            if(gate === "rxx") {
              let ionToPartnerIndexMapTemp = {...ionToPartnerIndexMap};
              for(let i: number = 0; i < ionStacks[Area.Compute.toString()].length; i++) {
                let adjustedIon = {...ionStacks[Area.Compute.toString()][i]};
                adjustedIon.operationHistory.pop();
                newComputeIons.push(adjustedIon);
                let partnerIndex = ionToPartnerIndexMapTemp[adjustedIon.label];
                partnerIndex = partnerIndex - 1;
                ionToPartnerIndexMapTemp[adjustedIon.label] = partnerIndex;
              }
              setIonToPartnerIndexMap({...ionToPartnerIndexMapTemp});
            } else {
              let target = instruction["target"];
              for(let i: number = 0; i < ionStacks[Area.Compute.toString()].length; i++) {
                let adjustedIon = {...ionStacks[Area.Compute.toString()][i]};
                if(i === target) {
                  adjustedIon.operationHistory.pop();
                }
                newComputeIons.push(adjustedIon);
              }
    
            }
          } else {
            if(gate === "rxx") {
              let ionToPartnerIndexMapTemp = {...ionToPartnerIndexMap};
              for(let i: number = 0; i < ionStacks[Area.Compute.toString()].length; i++) {
                let adjustedIon = {...ionStacks[Area.Compute.toString()][i]};
                adjustedIon.operationHistory.push(Operation.Gate);
                newComputeIons.push(adjustedIon);
                let partnerIndex = ionToPartnerIndexMapTemp[adjustedIon.label];
                partnerIndex = partnerIndex + 1;
                ionToPartnerIndexMapTemp[adjustedIon.label] = partnerIndex;;
              }
              setIonToPartnerIndexMap({...ionToPartnerIndexMapTemp});
            } else {
              let target = instruction["target"];
              for(let i: number = 0; i < ionStacks[Area.Compute.toString()].length; i++) {
                let adjustedIon = {...ionStacks[Area.Compute.toString()][i]};
                if(i === target) {
                  adjustedIon.operationHistory.push(Operation.Gate);
                }
                newComputeIons.push(adjustedIon);
              }
            }
          }
          setIonStacks({
            ...ionStacks,
            [Area.Compute.toString()]: [...newComputeIons]
          });
        }
        if(instruction['type'] === "move"){
          let start: Area | null = null;
          let end: Area | null = null;
    
          start = getArea(instruction['location1']);
          end = getArea(instruction['location2']);
    
          if(start !== null && end !== null){
            if(reversed){
              setIonStacks({...move(end, start, {...ionStacks})});
            } else {
              setIonStacks({...move(start, end, {...ionStacks})});
            }
          }
        }
    }

    function ionIndexListToIonList(ionIndices: number[]): Ion[]{
      let ionList = [] as Ion[];
      let initialIonOrderCopy = [...ionIndices];
      for (let i: number = 0; i < initialIonOrderCopy.length; i++){
        let label: string = "";
        if (ionIndices[i] != 255){
          label = ionIndices[i].toString();
        }
        ionList.push({x: 0, y: 0, label: label, operationHistory: [], area: Area.Storage})
      }
      return ionList;
    }

    function getArea(instruction: string) : Area | null{
        let area: Area | null = null;
        if(instruction === "storage") {
          area = Area.Storage
        } else if (instruction === "spam") {
          area = Area.Spam
        } else if (instruction === "temp_storage") {
          area = Area.Tempstorage
        } else if (instruction.split("[").length > 1 && instruction.split("[")[0] === "compute") {
          area = Area.Compute
        }
        return area;
    }

    function move(start: Area, end: Area, ionStackDict: {[area: string]: Ion[]}): {[area: string]: Ion[]} {
        let newIonStackDict = {...ionStackDict};
        if(start !== end) {
          let startStack = [...newIonStackDict[start.toString()]];
          let endStack = [...newIonStackDict[end.toString()]];
    
          let movedIon = startStack.pop();
          if(movedIon !== undefined) {
            endStack.push(movedIon);
    
            newIonStackDict = {
              ...newIonStackDict,
              [start.toString()]: [...startStack],
              [end.toString()]: [...endStack]
            };
          }
        }
        return newIonStackDict;
    }

    function preprocesTiasm(tiasmJson_input: any) {
        let initial_ion_order_json = tiasmJson_input["initial_ion_order"]
        let ionStacksTemp = {"0": ionIndexListToIonList(initial_ion_order_json["storage"]), 
                             "1": ionIndexListToIonList(initial_ion_order_json["temp_storage"]), 
                             "2": ionIndexListToIonList(initial_ion_order_json["compute"]), 
                             "3": ionIndexListToIonList(initial_ion_order_json["spam"])} as {[area: string]: Ion[]};

        let ionToPartnersMapTemp = {} as {[ionID: string]: string[]};
        let ionToPartnerIndexMapTemp = {} as {[ionID: string]: number};


        let tiasmJson = tiasmJson_input["data"];
    
        for(let i: number = 0; i < tiasmJson.length; i++) {
    
          if(tiasmJson[i]['type'] === "move") {
            let start: Area | null = null;
            let end: Area | null = null;
      
            start = getArea(tiasmJson[i]['location1']);
            end = getArea(tiasmJson[i]['location2']);
    
            if(start !== null && end !== null){
              ionStacksTemp = {...move(start, end, {...ionStacksTemp})};
            }
          } else if(tiasmJson[i]['type'] === "gate" && tiasmJson[i]["op"] === "rxx" && ionStacksTemp[Area.Compute.toString()].length === 2){
    
            let ionID1: string = ionStacksTemp[Area.Compute.toString()][0].label;
            let ionID2: string = ionStacksTemp[Area.Compute.toString()][1].label;
    
            if(ionID1 in ionToPartnersMapTemp){
              ionToPartnersMapTemp = {...ionToPartnersMapTemp, [ionID1] : [...ionToPartnersMapTemp[ionID1], ionID2]};
            } else {
              ionToPartnersMapTemp = {...ionToPartnersMapTemp, [ionID1] : [ionID2]};
            }
    
            if(ionID2 in ionToPartnersMapTemp){
              ionToPartnersMapTemp = {...ionToPartnersMapTemp, [ionID2] : [...ionToPartnersMapTemp[ionID2], ionID1]};
            } else {
              ionToPartnersMapTemp = {...ionToPartnersMapTemp, [ionID2] : [ionID1]};
            }
          }
        }
    
        //Initialize ionToPartnerIndexMap
        for (const id in ionToPartnersMapTemp){
          ionToPartnerIndexMapTemp[id] = 0;
        }
    



        setIonToPartnersMap({...ionToPartnersMapTemp});
        setIonToPartnerIndexMap({...ionToPartnerIndexMapTemp});
    }

    function get_ion_radius(ion_spacing: number) {
        if(ion_spacing < 100) {
          return ion_spacing /2.5
        } else {
          return 100 / 4
        }
    }

    function printTiasmInstruction(instruction: any, index: number) {

        let instruction_string = "";
    
        if(instruction["type"] === "classical_register"){
            instruction_string = "classical_register(" + instruction["size"] + ");"
        }
        else if (instruction["type"] === "meta_data") {
            instruction_string = "initial_ion_order(" + instruction["initial_ion_order"].toString() + ");"
        }
        else if (instruction["type"] === "move") {
          instruction_string = "move(" + instruction["location1"].toString() + "," + instruction["location2"].toString() + ");"
        }
        else if (instruction["type"] === "quantum_register") {
          instruction_string = "quantum_register(" + instruction["size"].toString() + ");"
        }
        else if (instruction["type"] === "prepare") {
          instruction_string = "prepare(" + instruction["location"].toString() + ");"
        }
        else if (instruction["type"] === "gate") {
          instruction_string = instruction["op"].toString() + "(" + instruction["angle"] + ");"
        }
        else if (instruction["type"] === "measure") {
          instruction_string = "measure -> " + instruction["target"].toString() + ";"
        }
    
        return (
          <Typography 
            sx={(theme) => ({overflowWrap: "anywhere"})}
            key={"tiasm" + index.toString()}
            align={"left"}
            style={{fontWeight: index === tiasmIndex? "bold": "normal"}}
            color={index === tiasmIndex? "text.primary": "text.secondary"}
          >
            {instruction_string}
          </Typography>
        )
    }

    return (
        <PageLayout pageType={PageType.CompilerVisualization}>
            <Box sx={(theme) => ({display: "flex", backgroundColor: theme.palette.background.paper, height: "100%", width: "100%", flexDirection: "rox", flexWrap:"wrap", justifyContent: "center"})}>
                <Box sx={(theme) => ({ml:1, mr: 1,width: theme.customSizes.standardTextfield,display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center"})}>
                    <Typography sx={{mb: 2}} variant={"h6"} color={"text.primary"}>OpenQASM2</Typography>
                    <CustomTextArea
                        placeholder=""
                        //sx={{mb: 2, alignItems: "flex-start"}}
                        value={openQASMText}
                        //inputProps={{style:{height: "65vh"}}}
                        onChange={(e) => setOpenQASMText(e.target.value)}
                    />
                    {!isLoading &&
                    <Button
                        sx={(theme) => ({mb: 3, height: theme.customSizes.buttonHeight, alignSelf: "center"})}
                        type="button"
                        style={{boxShadow: "none"}}
                        size={"small"}
                        variant="contained"
                        color={"primary"}
                        //startIcon={<EditIcon />}
                        onClick={onClickCompile}
                    >
                        Compile
                    </Button>
                    }
                    {isLoading &&
                        <Box sx={{mb: 3}}>
                          <CircularProgress/>
                        </Box>
                    }
                </Box>
                <Box sx={(theme) => ({ml:1, mr: 1,width: theme.customSizes.standardTextfield,display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center"})}>
                    <Typography sx={{mb: 2}} variant={"h6"} color={"text.primary"}>TIASM</Typography>
                    <Box sx={(theme) => ({overflow: "auto", padding: theme.spacing(2), boxSizing: "border-box", mb: 2, width: theme.customSizes.standardTextfield, height: "75vmin",border: "1px solid "+theme.palette.divider})}>
                        {tiasmJson.length > 0 &&
                            <Typography
                              sx={(theme) => ({overflowWrap: "anywhere", width: "100px"})}
                              key={"tiasm" + "-1"}
                              color={"text.secondary"}
                              align={"left"}
                            >
                              {"TIASM 1.0;"}
                            </Typography>
                        }
                        {
                            tiasmJson.map((instruction: any, index: number) => 
                                printTiasmInstruction(instruction, index)
                            )
                        }   
                    </Box>
                    <Box sx={{display: "flex", mb: 2}}>
                      <IconButton
                          sx={{mr: 1, alignSelf: "center"}}
                          color={"primary"}
                          onClick={onClickPrevious}
                      >
                          <ArrowBackIcon/>
                      </IconButton>
                      <IconButton
                          sx={{ml: 1, alignSelf: "center"}}
                          color={"primary"}
                          onClick={onClickNext}
                      >
                          <ArrowForwardIcon/>
                      </IconButton>
                    </Box>
                </Box>
                <Box sx={{ml:1, mr: 1,minWidth: "400px",display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", flexGrow: 2}}>
                    <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
                      <Typography sx={{alignSelf: "center"}} variant={"h6"} color={"text.primary"}>Trapped Ions</Typography>
                      <IconButton
                          sx={{ml: 1, alignSelf: "center"}}
                          color={"primary"}
                          onClick={downloadSVG}
                      >
                          <FileDownloadIcon/>
                      </IconButton>
                    </Box>
                    <Box sx={(theme) => ({boxSizing: "border-box", mb: 2, width: "100%", height: "75vmin",border: "0px black solid"})}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={graphViewBox}
                        id={"background"}
                        className={"ions-input"}
                    >
                      <IonChip
                        storage={arrangeIons(ionStacks[Area.Storage.toString()], Area.Storage)}
                        compute={arrangeIons(ionStacks[Area.Compute.toString()], Area.Compute)}
                        tempstorage={arrangeIons(ionStacks[Area.Tempstorage.toString()], Area.Tempstorage)}
                        spam={arrangeIons(ionStacks[Area.Spam.toString()], Area.Spam)}
                        radius={get_ion_radius(ionSpacing)}
                        comment={commentHistory.length > 0 ? commentHistory[commentHistory.length -1] : ""}
                        ionToPartnersMap={ionToPartnersMap}
                        ionToPartnerIndexMap={ionToPartnerIndexMap}
                        chipCutoffY={chipCutoffY}
                      />
                    </svg>   
                    </Box>
                </Box>

                <Box>

                </Box>
            </Box>
        </PageLayout>
    )
}