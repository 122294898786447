import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { ChevronLeft } from '@mui/icons-material';
import qvls_logo_dark_small from '../../components/Logos/qvls_logo_dark_small.svg';
import qvls_logo_lightblue_small from '../../components/Logos/qvls_logo_lightblue_small.svg';
import { PageType, PageBase } from '../../components/models';
import GroupIcon from '@mui/icons-material/Group';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import BorderInnerIcon from '@mui/icons-material/BorderInner';
import ArticleIcon from '@mui/icons-material/Article';
import HubIcon from '@mui/icons-material/Hub';
import { Divider, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EditIcon from '@mui/icons-material/Edit';

const Logo =  styled('img')`
    width: ${props => props.theme.customSizes.iconSmall};
    margin-left: ${props => props.theme.spacing(2)};
    margin-right: ${props => props.theme.spacing(0.5)};
`

export const drawerWidth = 8*29;

interface Props {
    menuOpen: boolean,
    isDarkMode: boolean,
    pages: PageBase[],
    pageType: PageType,
    handleMenuChange(menuIsOpen: boolean): void
}

export default function NavigationMenu(props: Props) {

  const handleDrawerToggle = () => {
    props.handleMenuChange(!props.menuOpen);
  };

  function getListItemStyle(isSelected: boolean){
    if(!isSelected){
      return (theme: any) => ({color: theme.palette.text.secondary});
    } else {
      return (theme: any) => ({color: theme.palette.text.primary});
    }
  }

  const drawer = (
    <Box sx={(theme) => ({height: "100%", backgroundColor: theme.customBackground.highlight, display: "flex", flexDirection: "column", justifyContent: "space-between"})}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Box sx={{display: "flex", justifyContent: "space-between"}}>
            <Box sx={{display: "flex"}}>
              <Logo src={props.isDarkMode? qvls_logo_dark_small : qvls_logo_lightblue_small}/>
              <Divider color={props.isDarkMode? "white": "black"} sx={(theme) => ({height: "45%", alignSelf: "center", mr: 0.5})}  orientation='vertical'/>
              <Typography color={"text.primary"} sx={{fontSize: 18, fontWeight: "500", alignSelf: "center"}}>{"Cloud"}</Typography>
            </Box>
            <IconButton color={"primary"} sx={{m: 1}} onClick={() => handleDrawerToggle()}>
              <ChevronLeft sx={(theme) => ({color: theme.customColors.primaryOnHighlightBackground})}/>
            </IconButton>
          </Box>
          <List>
          {props.pages.map(page => (
            <ListItem sx={(theme) => ({mb: 1, borderLeft: props.pageType === page.pageType? "3px solid "+theme.palette.text.primary: ""})} selected={props.pageType === page.pageType} key={page.name} disablePadding >
              <Link style={{ textDecoration: 'none', width: "100%"}} to={"/"+page.name.replaceAll(/\s/g,'')}>
              <ListItemButton>
                <Box sx={{mr: 2}}>
                  {page.pageType === PageType.ManageUsers &&
                    <GroupIcon fontSize={"small"} sx={getListItemStyle(props.pageType === page.pageType)}/>
                  }
                  {page.pageType === PageType.ManageJobs &&
                    <WorkHistoryIcon fontSize={"small"} sx={getListItemStyle(props.pageType === page.pageType)}/>
                  }
                  {page.pageType === PageType.CompilerVisualization &&
                    <BorderInnerIcon fontSize={"small"} sx={getListItemStyle(props.pageType === page.pageType)}/>
                  }
                  {page.pageType === PageType.API &&
                    <HubIcon fontSize={"small"} sx={getListItemStyle(props.pageType === page.pageType)}/>
                  }
                  {page.pageType === PageType.Documentation &&
                    <MenuBookIcon fontSize={"small"} sx={getListItemStyle(props.pageType === page.pageType)}/>
                  }
                  {page.pageType === PageType.EditDocumentation &&
                    <EditIcon fontSize={"small"} sx={getListItemStyle(props.pageType === page.pageType)}/>
                  }
                  {page.pageType === PageType.Settings &&
                    <SettingsIcon fontSize={"small"} sx={getListItemStyle(props.pageType === page.pageType)}/>
                  }
                  {page.pageType === PageType.Logs &&
                    <ArticleIcon fontSize={"small"} sx={getListItemStyle(props.pageType === page.pageType)}/>
                  }
                  </Box>
                <Typography variant={"subtitle2"} color={props.pageType === page.pageType? "text.primary": "text.secondary"}>{page.name}</Typography>
              </ListItemButton>
              </Link>
            </ListItem>
          ))}
          </List>
        </Box>
    </Box>
  );

  //const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{display: "flex"}}>
      <Box
        component="nav"
        sx={{width: { sm: drawerWidth }, flexShrink: { sm: 0 }, padding: 0 }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          //container={container}
          variant="temporary"
          open={props.menuOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          open={props.menuOpen}
          variant="persistent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
