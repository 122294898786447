import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import { UserBase } from '../../components/models';
import { Typography } from '@mui/material';
import CustomAvatar from '../../components/CustomAvatar';
import { AppContext } from '../../components/AppContext';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { useContext, useState } from 'react';

interface Props {
  loggedInUser: UserBase | undefined
}

export default function AccountMenu(props: Props) {
  const appContext = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{position: "absolute", top: "7px", right: "8px", zIndex: 1}}>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {/* <Tooltip title="Account settings"> */}
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <CustomAvatar size={25} fontSize={14} loggedInUser={props.loggedInUser}/>
          </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            bgcolor: "background.default",
            filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.75))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.default',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props.loggedInUser !== undefined &&
          <Box sx={{display: "flex", ml: 2, mt: 1, mr: 2, mb: 1, alignItems: "center"}}>
            <Box sx={{mr: 2}}>
              <CustomAvatar size={35} fontSize={18} loggedInUser={props.loggedInUser}/>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", overflow: "hidden"}}>
              <Typography    
                sx={{
                  mb: 0.5,
                  fontWeight: 500
                }} color={"text.primary"}>{props.loggedInUser === undefined? "" : props.loggedInUser.full_name}</Typography>
              <Typography sx={{fontSize: 12}} color={"text.secondary"}>{props.loggedInUser === undefined? "" : props.loggedInUser.username}</Typography>
            </Box>
          </Box>
        }
        <MenuItem onClick={(e) => appContext.onImprint()}>
          <ListItemIcon sx={{ml: 1, mr: 1}}>
            <ContactPageIcon fontSize="small" />
          </ListItemIcon>
          Legal Information
        </MenuItem>
        <MenuItem onClick={(e) => appContext.onPrivacyPolicy()}>
          <ListItemIcon sx={{ml: 1, mr: 1}}>
            <AdminPanelSettingsIcon fontSize="small" />
          </ListItemIcon>
          Privacy Policy
        </MenuItem>
        {props.loggedInUser !== undefined &&
          <MenuItem onClick={(e) => appContext.onLogout()}>
            <ListItemIcon sx={{ml: 1, mr: 1}}>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        }
      </Menu>
    </Box>
  );
}
