import { Box, Tooltip } from "@mui/material";
import React from "react";
import { useTheme, styled } from '@mui/material/styles';
import qvls_logo_dark_small from './Logos/qvls_logo_dark_small.svg';
import qvls_logo_light_small from './Logos/qvls_logo_light_small.svg';

const LogoStyled =  styled('img')`
    width: ${props => props.theme.customSizes.iconSmall};
`

export default function Logo() {

    const theme = useTheme();

    return (
        <LogoStyled sx={{position: "absolute", left: "12px", top: "12px"}} alt="QVLS Logo" src={theme.palette.mode === 'dark'? qvls_logo_dark_small : qvls_logo_light_small}/>
    )
}
