import React, {useState, useContext, useEffect} from "react";
import {ContentType, PageType, RequestData} from "../../components/models";
import PageLayout from "../../components/PageLayout";
import MarkdownRenderer from "./MarkdownRenderer";
import { Box } from "@mui/system";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import CustomCodeEditor from "../../components/CustomCodeEditor";
import { fetchWrapper } from "../../components/utilities";
import { AppContext } from '../../components/AppContext';

function ErrorFallback({error, resetErrorBoundary}: any) {
    return (
      <Box role="alert" sx={{height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <Box>
            <Typography color="text.primary">Invalid markdown:</Typography>
            <pre>{error.message}</pre>
            <Box sx={{widht: "100%", display: "flex", justifyContent: "center"}}>            
                <Button onClick={resetErrorBoundary}  style={{boxShadow: "none"}} color={"primary"} variant={"contained"} autoFocus>
                    Render again
                </Button>
            </Box>
        </Box>
      </Box>
    )
}

export default function Documentation() {

    const [markdownIsInvalid, setMarkdownIsInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const appContext = useContext(AppContext);
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        getDocumentation();
    },[]);

    function onSuccessfullDocumentationLoading(data: any) {
        setMarkdown(data)
    }

    function getDocumentation() {

        setIsLoading(true);

        let requestData: RequestData = {
            url: "documentation/", method: 'GET', contentType: ContentType.json,
            body: undefined
          };
      
        fetchWrapper(
            requestData,
            onSuccessfullDocumentationLoading,
            appContext,
            () => setIsLoading(false)
        );
    }

    function onClickPublish(e: any) {
        let newDocumentation = {markdown: markdown};

        setIsPublishing(true);
    
        let requestData: RequestData = {
          url: "admin/documentation/", method: 'POST', contentType: ContentType.json, 
          body: JSON.stringify(newDocumentation)
        };
    
        fetchWrapper(
          requestData,
          () => {},
          appContext,
          () => setIsPublishing(false)
        );
    }

    return (
        <PageLayout pageType={PageType.EditDocumentation}>
            <Box sx={{display: "flex", flexDirection: "column", height: "100%", overflow: "scroll"}}>
                <Box sx={{mt: 1, height: "100%", overflow: "hidden"}}>
                    <Grid container rowSpacing={{xs: 6, md: 3, lg: 3}} sx={{overflow: "scroll", height: "100%"}}>
                        <Grid item xs={12} md={6} sx={{height: "99.5%"}} >
                            {isLoading &&
                                <Box sx={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                                    <CircularProgress/>
                                </Box>
                            }
                            {!isLoading &&
                                <CustomCodeEditor
                                    code={markdown}
                                    language={"md"}
                                    handleCodeChange={setMarkdown}
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={6} sx={{height: "99.5%"}}>
                            {isLoading &&
                                <Box sx={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                                    <CircularProgress/>
                                </Box>
                            }
                            {!isLoading &&
                                <Box sx={{overflow: "scroll", textOverflow: "ellipsis", wordWrap: "break-word", display: "block", ml: 2, mr: 0, mt: 0, height: "100%"}} maxWidth={"md"}>
                                    <MarkdownRenderer markdown={markdown} setMarkdownIsInvalid={setMarkdownIsInvalid} errorFallback={ErrorFallback}/>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{display: "flex", width: "100%", justifyContent: "center", mb: 1}}>
                    {isPublishing &&
                        <Box>
                            <CircularProgress/>
                        </Box>
                    }
                    {!isPublishing &&
                        <Button disabled={markdownIsInvalid} onClick={onClickPublish} sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} style={{boxShadow: "none"}} color={"primary"} variant={"contained"} autoFocus>
                            Publish
                        </Button>
                    }
                </Box>
            </Box>
        </PageLayout>
    )
}