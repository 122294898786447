import { Box } from "@mui/material";
import React, { useEffect } from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useTheme } from '@mui/material/styles';

interface Props {
    mt?: number,
    code: string,
    language: string,
    disabled?: boolean,
    width?: string,
    height?: string,
    handleCodeChange?(code: string): void | undefined
}

export default function CustomCodeEditor(props: Props) {

    const theme = useTheme();

    useEffect(() => {
        if(theme.palette.mode === "dark"){
            document.documentElement.setAttribute('data-color-mode', 'dark');
        } else {
            document.documentElement.setAttribute('data-color-mode', 'light');
        }
    })

    return (
        <Box sx={{width: props.width? props.width: "99%", height: props.height? props.height: "100%", mt: props.mt? props.mt: 0, overflow:"auto", border: "1px solid "+ theme.palette.divider}}>
            <CodeEditor
                value={props.code}
                language={props.language}
                onChange={(evn) => props.handleCodeChange !== undefined? props.handleCodeChange(evn.target.value): {}}
                padding={15}
                disabled={props.disabled}
                style={{
                    //color: theme.palette.text.primary,
                    fontSize: 14,
                    //backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                    backgroundColor: "transparent",
                    //backgroundColor: theme.palette.background.paper,
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
            />
        </Box>
    )
}