import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import { StorageKeys } from "../../components/models";

export default function RequireAuth({ children }: { children: JSX.Element }) {
    const appContext = useContext(AppContext);
    let location = useLocation();
    let token = localStorage.getItem(StorageKeys.Token);

    if (appContext.userData === undefined || token === null) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  
    return children;
  }