import { Box, Typography } from '@mui/material';
import * as React from 'react';
import qvls_logo_light_bar from '../components/Logos/QVLS_Bar_light.svg';
import qvls_logo_dark_bar from '../components/Logos/QVLS_Bar_dark.svg';

interface Props {
    darkMode: boolean,
    text: string,
    size: number
}

export default function LogoWithText(props: Props) {
    
    return (
        <Box sx={{display: "flex", alignItems: "center"}}>
            <img alt="QVLS Logo" style={{width: (props.size*8).toString()+"px", height: "auto"}}src={props.darkMode? qvls_logo_dark_bar: qvls_logo_light_bar}></img>
            <Typography sx={{ml: 0.25,color: props.darkMode? "white": "dark", fontSize: (props.size*8).toString()+"px", fontWeight: "500"}} color="text.primary">{props.text}</Typography>
        </Box>
    )
}