import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import {ModalType, JobModal, JobStatus, Role, Job, RequestData, ContentType} from '../../components/models';
import { Box, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AppContext } from '../../components/AppContext';
import { fetchWrapper } from '../../components/utilities';

interface Props {
  handleClose: any,
  modal: JobModal,
  jobs: Job[],
  setJobs: React.Dispatch<React.SetStateAction<Job[]>>
}

export default function EditJobModal(props: Props) {
  const appContext = useContext(AppContext);
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const [jobName, setJobName] = useState(props.modal.name === undefined? "" : props.modal.name);

  function deleteJob() {
    let newJobs = [...props.jobs].filter(job => job.job_id !== props.modal.jobID);
    props.setJobs([...newJobs]);
  }

  function cancelJob() {
    let newJobs = []
    if(props.modal.status !== undefined && props.modal.status === JobStatus.Running){
      newJobs = [...props.jobs].map(job => job.job_id === props.modal.jobID? {...job, status: JobStatus[JobStatus.Cancelling]} : job);
    } else {
      newJobs = [...props.jobs].map(job => job.job_id === props.modal.jobID? {...job, status: JobStatus[JobStatus.Canceled]} : job);
    }
    props.setJobs([...newJobs]);
  }

  function editJobName() {
    let newJobs = [...props.jobs].map(job => job.job_id === props.modal.jobID? {...job, name: jobName} : job);
    props.setJobs([...newJobs]);
  }

  function onSuccessJobEdit(data : any) {
    if(props.modal.type === ModalType.Delete){
      deleteJob();
    } else if(props.modal.type === ModalType.Cancel) {
      cancelJob();
    } else if(props.modal.type === ModalType.Edit) {
      editJobName()
    }
    props.handleClose();
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();

    setIsSaving(true);

    if(props.modal.jobID === undefined) {
      throw 'Job ID is not defined.'
    }

    let url = "";
    if(appContext.userData?.role_id === Number(Role.Admin)){
        url += "admin/";
    }
    url += "jobs/?job_id="+props.modal.jobID.toString();
    
    if(props.modal.type === ModalType.Edit){
      url += "&name="+jobName;
    } else if (props.modal.type === ModalType.Cancel || props.modal.type === ModalType.Delete) {
      let newStatus = JobStatus[JobStatus.Canceled];
      if(props.modal.type === ModalType.Delete){
        newStatus = JobStatus[JobStatus.Deleted];
      }
      url += "&status_name="+newStatus;
    } else {
      return; //Maybe even throw exception.
    }

    let requestData: RequestData = {
      url: url, method: "PUT", contentType: ContentType.json, 
      body: undefined
    }

    fetchWrapper(
      requestData,
      onSuccessJobEdit,
      appContext,
      () => setIsSaving(false)
    );
  }

  return (
      <Dialog
        PaperProps={{
          style: {backgroundColor: theme.palette.background.paper}
        }}
        open={props.modal.isOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.handleClose();
          }
        }}
        fullWidth
        maxWidth={"sm"}
        aria-labelledby={"Job modal"}
        onBackdropClick={undefined}
      >
        <DialogTitle id="responsive-dialog-title">
          {props.modal.type === ModalType.Cancel &&
            "Cancel job"
          }
          {props.modal.type === ModalType.Delete &&
            "Delete job"
          }
          {props.modal.type === ModalType.Edit &&
            "Edit job name"
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={"form"} id="updateUserForm" onSubmit={handleSubmit}>
            {props.modal.type === ModalType.Edit &&
              <TextField sx={{mt: 1}} fullWidth required label={"Job name"} value={jobName} onChange={(e) => setJobName(e.target.value)}/>
            }
            {props.modal.type !== ModalType.Edit &&
              <Box sx={{display: "flex", flexDirection: "column", alignItems: "start", ml:0, mr:0}}>
                <Typography>Are you sure you want to continue? This cannot be undone.</Typography>
              </Box>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "center", mb: 1}}>
          {isSaving &&
            <CircularProgress/>
          }
          {!isSaving &&
            <React.Fragment>
              <Button type="submit" form="updateUserForm" sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} color={"primary"} variant={"outlined"} autoFocus>
                {props.modal.type === ModalType.Edit &&
                  "Save"
                }
                {props.modal.type !== ModalType.Edit &&
                  "Continue"
                }
              </Button>
              <Button sx={(theme) => ({boxShadow: "none", width: theme.customSizes.dialogButtonWidth})} color={"error"} variant={"outlined"} autoFocus onClick={props.handleClose}>
                Cancel
              </Button>
            </React.Fragment>
          }
        </DialogActions>
      </Dialog>
  );
}
