import React, {useState, useEffect, useContext, useRef} from "react";
import { Job, Role, ModalType, JobModal, PageType, RequestData, ContentType, StorageKeys, Language, WSTypes, JobStatus} from "../../components/models";
import PageLayout from "../../components/PageLayout";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button, IconButton, TextField, Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import JobMenu from "./JobMenu";
import ViewJobModal from "./ViewJobModal";
import JobStatusComponent from "../../components/StatusComponents";
import EditJobModal from "./EditJobModal";
import AddJob from "./AddJobModal";
import { AppContext } from "../../components/AppContext";
import { websocketWrapper } from "../../components/utilities";
import DisplayDateTime from "../../components/DateTime";
import ViewErrorModal from "./ViewErrorModal";

export default function ManageJobs() {

    const appContext = useContext(AppContext);
    const [jobs, setJobs] = useState([] as Job[]);
    const [jobPrints, setJobPrints] = useState("");
    const [jobError, setJobError] = useState("");
    const [jobResult, setJobResult] = useState("");
    const [jobStatus, setJobStatus] = useState(undefined as JobStatus | undefined)
    const [isLoading, setIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [modal, setModal] = useState({isOpen: false, type: ModalType.View, jobID: -1} as JobModal);
    const ws = useRef(undefined as undefined | WebSocket);

    useEffect(() => {
        let url = "";
        if(appContext.userData?.role_id === Number(Role.Admin)){
            url += "admin/jobs/";
        } else {
            url += "jobs/"
        }
        setIsLoading(true);
        const jobSocket = websocketWrapper(
            url, 
            JSON.stringify({"type": WSTypes.filter, "payload": searchInput}), 
            onMessage, 
            () => {},
            appContext
        );
        ws.current = jobSocket;

        return () => {
            jobSocket?.close();
        }
      },[]);

    useEffect(() => {
        if(modal.isOpen && modal.type === ModalType.View && modal.jobID !== undefined){
            sendAddPrintListener(modal.jobID);
        }
    }, [modal]);

    function onMessage(data: any) {

        if(data[WSTypes.type] === WSTypes.jobs){
            let dataTemp = [...data[WSTypes.payload]];
            dataTemp.map((row: any) => {
                row['id'] = row.job_id;
                row['actions'] = "";
            });
            setJobs([...dataTemp]);
        }
        else if(data[WSTypes.type] === WSTypes.print){
            setJobResult(data[WSTypes.payload][WSTypes.result]);
            setJobPrints(data[WSTypes.payload][WSTypes.print]);
            setJobError(data[WSTypes.payload][WSTypes.error]);
            setJobStatus(data[WSTypes.payload][WSTypes.status_id])
        }
        else {
            console.log("Unsupported websocket message.");
        }
        setIsLoading(false);
    }

    function sendSearchInput(name: string){
        ws.current?.send(JSON.stringify({"type": WSTypes.filter, "payload": name}));
    }

    function sendAddPrintListener(job_id: number){
        ws.current?.send(JSON.stringify({"type": WSTypes.add_print_listener, "payload": job_id}));
    }

    function getColumns(setModal: React.Dispatch<React.SetStateAction<JobModal>>) {
        let columns1: GridColDef[] = [
            { field: 'actions', headerName: '', width: 60, renderCell: (cellValues: any) => {
                return (
                    <JobMenu setModal={setModal} jobID={cellValues.row.job_id} jobName={cellValues.row.name} backendID={Number(cellValues.row.target_id)} status={cellValues.row.status} shots={cellValues.row.shots} language={Language[cellValues.row.language as Language]}/>
                )}
            },
            { field: 'status', headerName: 'Status', width: 150, align:"left",
                renderCell: (cellValues: any) => {
                    return (
                        <JobStatusComponent status={cellValues.value} showStatusName={true}/>
                    )
                }
            },
            { field: 'name', headerName: 'Job name', flex: 1, maxWidth: 900, minWidth: 200, 
            renderCell: (cellValues: any) =>  (
                <Tooltip title={cellValues.row.name} >
                    <span className="MuiDataGrid-cellContent">{cellValues.row.name}</span>
                </Tooltip>)
            }
        ];

        if(appContext.userData?.role_id === Role.Admin){
            columns1.push({ field: 'username', headerName: 'Username', flex: 1, maxWidth: 900, minWidth: 200, 
            renderCell: (cellValues: any) =>  (
                <Tooltip title={cellValues.row.username} >
                    <span className="MuiDataGrid-cellContent">{cellValues.row.username}</span>
                </Tooltip>)
            })
        };

        let columns2: GridColDef[] = [ 
            { field: 'target', headerName: 'Target', width: 150, 
                renderCell: (cellValues: any) =>  (
                    <Tooltip title={cellValues.row.target} >
                        <span className="MuiDataGrid-cellContent">{cellValues.row.target}</span>
                    </Tooltip>)
            },
            { field: 'language', headerName: 'Input language', width: 120, 
                renderCell: (cellValues: any) =>  (
                <Tooltip title={cellValues.row.language} >
                    <span className="MuiDataGrid-cellContent">{cellValues.row.language}</span>
                </Tooltip>)
            },
            { field: 'created_on', headerName: 'Created on', width: 150,
                renderCell: (cellValues: any) => {
                    return (
                        <DisplayDateTime dateTime={cellValues.row.created_on}/>
                    )
                }
            },
            { field: 'edited_on', headerName: 'Last edited on', width: 150,
                renderCell: (cellValues: any) => {
                    return (
                        <DisplayDateTime dateTime={cellValues.row.edited_on}/>
                    )
                }
            }
        ];
        if(appContext.userData?.role_id === Role.Admin){
            columns2.push({ field: 'job_id', headerName: 'ID', width: 60,                 
            renderCell: (cellValues: any) =>  (
                <Tooltip title={cellValues.row.job_id} >
                    <span className="MuiDataGrid-cellContent">{cellValues.row.job_id}</span>
                </Tooltip>)})
        }

        return [...columns1, ...columns2] as GridColDef[];
    }

    function onKeyDown(e: any) {
        if(e.keyCode === 13){
            sendSearchInput(searchInput);
         }
    }

    function handleViewModalClosing(){
        setModal({...modal, isOpen: false})
        sendSearchInput(searchInput);
    }

    function onRowClick(e:any){
        let jobID = e['row']['job_id']
        let name = e['row']['name']
        setModal({isOpen: true, type: ModalType.View, jobID: jobID, name: name} as JobModal)
    }

    return (
        <PageLayout pageType={PageType.ManageJobs}>
            <Box sx={{display: "flex", flexWrap: "wrap"}}>
                {modal.isOpen && modal.type === ModalType.View &&
                    <ViewJobModal modal={modal} jobError={jobError} jobResult={jobResult} jobPrints={jobPrints} status={jobStatus} handleClose={handleViewModalClosing}/>
                }
                {modal.isOpen && modal.type === ModalType.Error &&
                    <ViewErrorModal modal={modal} handleClose={() => setModal({...modal, isOpen: false})}/>
                }
                {modal.isOpen && (modal.type === ModalType.Edit || modal.type === ModalType.Delete || modal.type === ModalType.Cancel) &&
                    <EditJobModal jobs={jobs} setJobs={setJobs} modal={modal} handleClose={() => setModal({...modal, isOpen: false})}/>
                }
                {modal.isOpen && (modal.type === ModalType.Create) &&
                    <AddJob getJobs={() => {}} modal={modal} handleClose={() => setModal({...modal, name: undefined, shots: undefined, backendID: undefined, language: undefined, jobID: undefined, isOpen: false})}/>
                }
                <TextField
                  sx={{alignSelf: "center", mr: 1, ml: 0, mb: 0, mt: 1}}
                  size="small"
                  margin="normal"
                  name="Job name"
                  label="Job name"
                  type="Job name"
                  id="Job name"
                  onBlur={() => sendSearchInput(searchInput)}
                  onKeyDown={(e) => onKeyDown(e)}
                  onChange={(e) => setSearchInput(e.target.value)}
                  InputProps={{endAdornment: <IconButton sx={{paddingRight: 0}} onClick={() => sendSearchInput(searchInput)}><SearchIcon color={"primary"}/></IconButton>}}
                />
                <Button
                    sx={(theme) => ({height: theme.customSizes.buttonHeight, mb: 0, alignSelf: "center", mt: 1})}
                    size="small"
                    type="button"
                    variant="outlined"
                    color={"primary"}
                    startIcon={<AddIcon />}
                    onClick={(e) => setModal({...modal, name: undefined, shots: undefined, backendID: undefined, language: undefined, jobID: undefined, isOpen: true, type: ModalType.Create})}
                >
                    Add Job
                </Button>
            </Box>

            <DataGrid
                sx={{'& .MuiDataGrid-row': { cursor: 'pointer' },
                    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                    {
                      outline: "none !important",
                    }, mt: 1}}
                loading={isLoading}
                rows={jobs}
                columns={getColumns(setModal)}
                pageSize={25}
                //rowsPerPageOptions={[5]}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                onRowDoubleClick={(e) => onRowClick(e)}
            /> 
        </PageLayout>
    )
}