import React, {useEffect, useState, useContext} from "react";
import {ContentType, PageType, RequestData} from "../../components/models";
import PageLayout from "../../components/PageLayout";
import MarkdownRenderer from "./MarkdownRenderer";
import { Box } from "@mui/system";
import { fetchWrapper } from "../../components/utilities";
import { AppContext } from '../../components/AppContext';
import { Button, CircularProgress, Typography } from "@mui/material";

function ErrorFallback({error, resetErrorBoundary}: any) {
    return (
      <Box role="alert" sx={{height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <Box>
            <Typography color="text.primary">Something went wrong. The documentation cannot be rendered.</Typography>
        </Box>
      </Box>
    )
}

export default function Documentation() {

    const [isLoading, setIsLoading] = useState(false);
    const appContext = useContext(AppContext);
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        getDocumentation();
    },[]);

    function onSuccessfullDocumentationLoading(data: any) {
        setMarkdown(data)
    }

    function getDocumentation() {

        setIsLoading(true);

        let requestData: RequestData = {
            url: "documentation/", method: 'GET', contentType: ContentType.json,
            body: undefined
          };
      
        fetchWrapper(
            requestData,
            onSuccessfullDocumentationLoading,
            appContext,
            () => setIsLoading(false)
        );
    }

    return (
        <PageLayout pageType={PageType.Documentation}>
            <Box sx={{display: "flex", width: "100%", height: "100%", justifyContent: "center", mt: 2, overflow: "hidden"}}>
                {isLoading &&
                    <Box sx={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                        <CircularProgress/>
                    </Box>
                }
                {!isLoading &&
                    <Box sx={{overflow: "scroll", textOverflow: "ellipsis", wordWrap: "break-word", display: "block", height: "100%", width: "100%"}}>
                        <Box sx={{overflow: "hidden", textOverflow: "ellipsis", wordWrap: "break-word", display: "block", margin: "auto"}} maxWidth={"md"}>
                            <MarkdownRenderer markdown={markdown} errorFallback={ErrorFallback}/>
                        </Box>
                    </Box>
                }
            </Box>
        </PageLayout>
    )
}
