import React from "react";
import {JobStatus, Role} from "./models";
import { Box, Typography } from "@mui/material";
import IonLoader, { Ion } from "./IonLoader";
import { AppContext } from "./AppContext";
import { useTheme } from '@mui/material/styles';

const errorStatus: string[] = [JobStatus[JobStatus.Canceled],JobStatus[JobStatus.Failed],JobStatus[JobStatus.Cancelling]];

interface Props {
    status: string,
    showStatusName: boolean
}

export default function JobStatusComponent(props: Props) {

    const theme = useTheme();
    const appContext = React.useContext(AppContext);

    function getColor(statusName: string){
        if(errorStatus.includes(statusName)){
            return theme.palette.error.main;
        } else if(JobStatus[JobStatus.Submitted] === statusName || JobStatus[JobStatus.Uncompiled] === statusName) {
            return theme.palette.warning.light;
        } else if(JobStatus[JobStatus.Running] === statusName) {
            return theme.palette.warning.light;
        }
        else if(JobStatus[JobStatus.Completed] === statusName) {
            return theme.palette.success.light;
        } else {
            return theme.palette.primary.main;
        }
    }

    function getStatusName(statusName: string) {

        let isUncompiledAndNotAdmin = (JobStatus[JobStatus.Uncompiled] === statusName) && (appContext.userData?.role_id !== Number(Role.Admin))

        if(isUncompiledAndNotAdmin){
            return JobStatus[JobStatus.Submitted]
        }
        return statusName
    }

    return (
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Box sx={{mr: 1}}>
                {JobStatus[JobStatus.Running] === props.status &&
                    <IonLoader color={getColor(props.status)}/>
                }
                {JobStatus[JobStatus.Running] !== props.status &&
                    <Ion color={getColor(props.status)}/>
                }
            </Box>
            {props.showStatusName &&
                <Typography sx={{fontSize:"14px"}}>{getStatusName(props.status)}</Typography>
            }
        </Box>
    )
}

export function HttpStatusComponent(props: Props) {

    const theme = useTheme();

    function getColor(statusName: string){
        if(statusName.length > 0){
            if(statusName[0] === '2'){
                return theme.palette.success.light;
            } else if(statusName[0] === '3') {
                return theme.palette.primary.main;
            }else if(statusName[0] === '4') {
                return theme.palette.warning.light;
            }else if(statusName[0] === '5') {
                return theme.palette.error.main;
            } else {
                return theme.palette.primary.main;
            }
        } else {
            return theme.palette.primary.main;
        }
    }

    return (
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Box sx={{mr: 1}}>
                <Ion color={getColor(props.status)}/>
            </Box>
            {props.showStatusName &&
                <Typography sx={{fontSize:"14px"}}>{props.status}</Typography>
            }
        </Box>
    )
}