import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import { JobModal, JobStatus, Language, ModalType, Role } from '../../components/models';
import CancelIcon from '@mui/icons-material/Cancel';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import ReplayIcon from '@mui/icons-material/Replay';
import { AppContext } from "../../components/AppContext";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<JobModal>>,
  jobID: number,
  jobName: string,
  status: string,
  backendID: number,
  language: Language,
  shots: string
}

export default function JobMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const appContext = React.useContext(AppContext);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {/* <Tooltip title="Account settings"> */}
          <IconButton 
            onClick={handleClick}
            sx={{color: "text.secondary"}}
            aria-controls={open ? 'job-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon />
          </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="job-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            bgcolor: "background.default",
            filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.4))',
          },
        }}
      >
        <MenuItem onClick={(e) => props.setModal({type: ModalType.View, isOpen: true, jobID: props.jobID, name: props.jobName} as JobModal)}>
          <ListItemIcon>
            <TextSnippetIcon fontSize="small" color={"primary"}/>
          </ListItemIcon>
          <Typography color={"text.primary"}>Show data</Typography>
        </MenuItem>
        <MenuItem onClick={(e) => props.setModal({type: ModalType.Create, isOpen: true, jobID: props.jobID, name: props.jobName, backendID: props.backendID, shots: props.shots, language: props.language} as JobModal)}>
          <ListItemIcon>
            <ReplayIcon fontSize="small" color={"primary"}/>
          </ListItemIcon>
          <Typography color={"text.primary"}>Resubmit</Typography>
        </MenuItem>
        <MenuItem onClick={(e) => props.setModal({type: ModalType.Edit, isOpen: true, jobID: props.jobID, name: props.jobName} as JobModal)}>
          <ListItemIcon>
            <EditIcon fontSize="small" color={"primary"}/>
          </ListItemIcon>
          <Typography color={"text.primary"}>Edit name</Typography>
        </MenuItem>
        {props.status === JobStatus[JobStatus.Failed] && appContext.userData?.role_id === Number(Role.Admin) &&
          <MenuItem onClick={(e) => props.setModal({type: ModalType.Error, isOpen: true, jobID: props.jobID, name: props.jobName} as JobModal)}>
            <ListItemIcon>
              <ErrorIcon fontSize="small" color={"error"}/>
            </ListItemIcon>
            <Typography color={"text.primary"}>Show error</Typography>
          </MenuItem>
        }
        {props.status !== JobStatus[JobStatus.Canceled] && props.status !== JobStatus[JobStatus.Completed] && props.status !== JobStatus[JobStatus.Failed] && props.status !== JobStatus[JobStatus.Cancelling] &&
          <MenuItem onClick={(e) => props.setModal({type: ModalType.Cancel, isOpen: true, jobID: props.jobID, name: props.jobName, status: props.status as unknown as JobStatus} as JobModal)}>
            <ListItemIcon>
              <CancelIcon fontSize="small" color={"error"}/>
            </ListItemIcon>
            <Typography color={"text.primary"}>Cancel</Typography>
          </MenuItem>
        }
        {props.status !== JobStatus[JobStatus.Running] && props.status !== JobStatus[JobStatus.Cancelling] &&
          <MenuItem onClick={(e) => props.setModal({type: ModalType.Delete, isOpen: true, jobID: props.jobID, name: props.jobName} as JobModal)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" color={"error"}/>
            </ListItemIcon>
            <Typography color={"text.primary"}>Delete</Typography>
          </MenuItem>
        }
      </Menu>
    </React.Fragment>
  );
}
