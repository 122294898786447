import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import {PageType, Page, UserBase, PageBase, RequestData, ContentType, StorageKeys, ModalType, Modal} from '../../components/models';
import CircularProgress from '@mui/material/CircularProgress';
import LogoWithText from '../../components/LogoWithText';
import { AppContext } from '../../components/AppContext';
import { fetchWrapper } from '../../components/utilities';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../components/css/custom-link.css'
import SignUpModal from './SignUpModal';
import ForgotPasswordModal from './ForgotPasswordModal';

const LoginContainer = styled('div')`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
`

const LoginContainer2 = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.palette.background.paper};
`


function Copyright(props: any) {
  return (
      <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ mb: 1}}>
        {' © '}
        {new Date().getFullYear()}
        {' QVLS | Quantum Valley Lower Saxony e. V.'}
      </Typography>
  );
}

interface Props {
  modal: Modal,
  handleUserDataChange(userData: UserBase): void,
  handlePagesChange(pages: PageBase[]): void
}

export default function SignIn(props: Props) {

  let navigate = useNavigate();
  let location = useLocation() as any;
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(props.modal);

  function onSuccessfulLogin(data: any) {
    if(data.hasOwnProperty('access_token') && data.hasOwnProperty('pages')){
      localStorage.setItem(StorageKeys.Token, data.access_token);
      let user_id = Number(data["user_id"]);
      let role_id = Number(data["role_id"]);
      let full_name = data["full_name"];
      let username = data["username"];
      let pages_data = data["pages"];
      let pages = [] as Page[];
      for(let i = 0; i < pages_data.length; i++){
        if(pages_data[i].page_id in PageType){
          pages.push({name: pages_data[i].name, pageType: pages_data[i].page_id as PageType} as Page);
        }
      }
      if(pages.length > 0){
        setError(false);
        props.handlePagesChange([...pages]);
        if(location.state === null || location.state.from.pathname === "/"){
          navigate("/"+pages[0].name);
        } else {
          // Send them back to the page they tried to visit when they were
          // redirected to the login page. Use { replace: true } so we don't create
          // another entry in the history stack for the login page.  This means that
          // when they get to the protected page and click the back button, they
          // won't end up back on the login page, which is also really nice for the
          // user experience.
          navigate(location.state.from.pathname, {replace : true});
        }
      } else {
        appContext.setError({isVisible: true, message: ""});
      }

      props.handleUserDataChange({username: username, role_id: role_id, user_id: user_id, full_name: full_name});
    } else {
      appContext.setError({isVisible: true, message: ""});
    }
    setIsLoading(false);
  }

  function onCleanup() {
    setIsLoading(false);
  }

  function getLinkStyle() {
    return {fontSize: "0.875rem", color: theme.palette.mode === 'dark'? "rgba(255, 255, 255, 0.7)":"rgb(68 89 101)"}
  }

  //React.FormEvent<HTMLFormElement>
  const handleSubmit = (event: any) => {
    setIsLoading(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let username = data.get('username')?.toString();
    let password = data.get('password')?.toString();

    if(username !== undefined && password !== undefined){

      let requestData: RequestData = {
        url: "webapp/", method: "POST", contentType: ContentType.urlencoded, 
        body: new URLSearchParams({"username": username, "password": password})
      }

      fetchWrapper(
        requestData,
        onSuccessfulLogin,
        appContext,
        onCleanup
      );
    }
  };

  function handleModalClose() {
    setModal({...modal, isOpen: false});
    navigate("/");
  }

  return (
    <LoginContainer>
      {modal.isOpen && (modal.type === ModalType.Create || modal.type === ModalType.View || modal.type === ModalType.Success) &&
        <SignUpModal modal={modal} setModal={setModal} handleClose={handleModalClose}/>
      }
      {modal.isOpen && (modal.type === ModalType.ForgotPassword || modal.type === ModalType.EditPassword || modal.type === ModalType.SendResetLink || modal.type === ModalType.SuccessfulReset) &&
        <ForgotPasswordModal modal={modal} setModal={setModal} handleClose={handleModalClose}/>
      }
      <LoginContainer2>
        <Box sx={{display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"}} 
          component={"form"} 
          onSubmit={handleSubmit}
        >
            <Box sx={{display: "flex"}}>
              <LogoWithText
                darkMode={theme.palette.mode === 'dark'}
                text={"Cloud"}
                size={6}
              />
              <Typography color="text.secondary">(beta)</Typography>
            </Box>
            <TextField
              margin="normal"
              error={error}
              required
              sx={(theme) => ({
                width: theme.customSizes.standardTextfield,
              })}
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              error={error}
              margin="normal"
              required
              sx={(theme) => ({
                width: theme.customSizes.standardTextfield,
              })}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Box sx={(theme) => ({
                width: theme.customSizes.standardTextfield,
                display: "flex",
                mt: 1
              })}>
              <Link onClick={() => setModal({isOpen: true, type: ModalType.ForgotPassword})} style={{...getLinkStyle()}} to="/forgotpassword">
                Forgot password?
              </Link>
            </Box>
            <Box
              sx={(theme) => ({
                width: theme.customSizes.standardTextfield,
                color: theme.palette.text.secondary
              })}
            >
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
            </Box>
            {!isLoading && 
              <Button
                type="submit"
                sx={(theme) => ({
                  width: theme.customSizes.standardTextfield,
                  mt: 4,
                  mb: 0
                })}
                style={{boxShadow: "none"}} 
                variant="contained"
                color={"primary"}
              >
                Sign In
              </Button>
            }
            {isLoading &&
              <Box sx={{mt: 2}}>
                <CircularProgress/>
              </Box>
            }
            <Box sx={(theme) => ({
                width: theme.customSizes.standardTextfield,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
                mb: 1
              })}>
              <Box style={{...getLinkStyle()}}>Don't have an account? </Box>
              <Link onClick={() => setModal({isOpen: true, type: ModalType.Create})} style={{...getLinkStyle(), marginLeft: "8px"}} to="/signup">
                 Sign up here
              </Link>
            </Box>
        </Box>
        <Copyright/>
        <Box sx={{mb: 2, width: "100%", display: "flex", justifyContent: "center"}}>
          <Link className="custom-link" style={{...getLinkStyle(), marginRight: "8px"}} to="/impressum/en">Legal Information</Link>
          <Link className="custom-link" style={{...getLinkStyle(), marginLeft: "8px"}} to="/datenschutzerklaerung/en">Privacy Statement</Link>
        </Box>
      </LoginContainer2>
    </LoginContainer>     
  );
}