import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ContentType, Modal, RequestData } from '../../components/models';
import { Box, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { passwordTooWeakText } from '../../components/texts';
import { fetchWrapper } from '../../components/utilities';
import { AppContext } from '../../components/AppContext';

interface Props {
    handleClose: any,
    modal: Modal,
}

export default function PasswordModal(props: Props) {
  const appContext = useContext(AppContext);
  const [newPasswordIsValid, setNewPasswordIsValid] = useState(true);
  const [currentPasswordIsValid, setCurrentPasswordIsValid] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    
    //VALIDATE INPUT
    if(newPassword !== newPasswordRepeat){
      return
    }

    let passwordIsStrong = theme.regex.strongPassword.test(newPassword);
    if(!passwordIsStrong){
      setNewPasswordIsValid(false);
      return;
    } else {
      setNewPasswordIsValid(true);
    }

    setIsSaving(true);
    let newUserData = {current_password: currentPassword, new_password: newPassword};

    let requestData: RequestData = {
      url: "user/", method: "PUT", contentType: ContentType.json, 
      body: JSON.stringify(newUserData)
    }

    fetchWrapper(
      requestData,
      () => props.handleClose(),
      appContext,
      () => setIsSaving(false)
    );
  }

  function handleNewPasswordChange(e: any) {
    let password = e.target.value;
    setNewPassword(password);
    let passwordIsStrong = theme.regex.strongPassword.test(password);
    if(!passwordIsStrong){
      setNewPasswordIsValid(false);
      return;
    } else {
      setNewPasswordIsValid(true);
    }
  }

  function handleCurrentPasswordChange(e: any) {
    if(!currentPasswordIsValid){
      setCurrentPasswordIsValid(true);
    }
    setCurrentPassword(e.target.value);
  }

  return (
      <Dialog
        PaperProps={{
          style: {backgroundColor: theme.palette.background.paper}
        }}
        fullScreen={fullScreen}
        open={props.modal.isOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.handleClose();
          }
        }}
        aria-labelledby={"Edit Password"}
        onBackdropClick={undefined}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Edit password"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={"form"} id="updateUserForm" onSubmit={handleSubmit}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", ml:6, mr:6}}>
              <TextField 
                required
                name={"CurrentPassword"} 
                sx={{width: theme.customSizes.standardTextfield, mt: 2, mb: 4}} 
                label={"Current password"} 
                value={currentPassword}
                error={!currentPasswordIsValid} 
                helperText={currentPasswordIsValid? "": "Wrong password."}
                onChange={handleCurrentPasswordChange} 
                type="password"
                inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
              />
              <TextField 
                required
                name={"New Password"} 
                sx={{width: theme.customSizes.standardTextfield, mb: 4}}
                label={"New password"} 
                error={newPassword !== "" && !newPasswordIsValid}
                value={newPassword} 
                helperText={(newPassword !== "" && !newPasswordIsValid)? passwordTooWeakText: ""}
                onChange={handleNewPasswordChange} 
                type="password"
                inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
              />
              <TextField 
                required
                name={"Repeat new password"} 
                sx={{width: theme.customSizes.standardTextfield, mb: 1}} 
                label={"Repeat new password"} 
                error={newPasswordRepeat !== "" && newPassword !== newPasswordRepeat}
                value={newPasswordRepeat} 
                onChange={(e) => setNewPasswordRepeat(e.target.value)} 
                type="password"
                inputProps={{maxLength: theme.customTextfieldLength.standardTextfieldLength}}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "center", mb: 1}}>
          {isSaving &&
            <CircularProgress/>
          }
          {!isSaving &&
            <React.Fragment>
              <Button type="submit" form="updateUserForm" sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} color={"primary"} variant={"outlined"} autoFocus>
                Save
              </Button>
              <Button sx={(theme) => ({width: theme.customSizes.dialogButtonWidth})} color={"error"} variant={"outlined"} autoFocus onClick={props.handleClose}>
                Cancel
              </Button>
            </React.Fragment>
          }
        </DialogActions>
      </Dialog>
  );
}
