import React, { useState, useEffect } from 'react';
import { darkTheme, lightTheme} from './Theme'
import { ThemeProvider, styled } from '@mui/material/styles';
import Login from '../Login/login';
import { Alert, AlertTitle, Box, Snackbar } from '@mui/material';
import AccountMenu from './AccountMenu';
import {PageType, UserBase, PageBase, StorageKeys, Error, AppContextType, TextLanguage, ModalType, Modal} from '../../components/models';
import NavigationMenu from './NavigationMenu';
import ManageUsers from '../ManageUsers/ManageUsers';
import ManageJobs from '../ManageJobs/ManageJobs';
import Documentation from '../Documentation/Documentation';
import EditDocumentation from '../Documentation/EditDocumentation';
import LegalInformation from '../LegalInformation/LegalInformation';
import PrivacyStatement from '../PrivacyStatement/PrivacyStatement';
import API from '../API/API';
import Settings from '../Settings/Settings';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import { AppContext } from '../../components/AppContext';
import RequireAuth from './RequireAuth';
import Logs from '../LogsPage/Logs';
import {getIsDarkMode, getPages, getUserData, getPageIDNameDict} from './StorageUtil';
import CompilerVisualization from '../CompilerVisualization/CompilerVisualization';
import Logo from '../../components/Logo';
import Language from '../../components/Language';

const Background = styled('div')`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.palette.background.paper}
`

function App() {

  let navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(getIsMenuOpen());
  const [isDarkMode, setIsDarkMode] = useState(getIsDarkMode());
  const [pages, setPages] = useState(getPages());
  const [loggedInUser, setLoggedInUser] = useState(getUserData());
  const [pageIDNameDict, setPageIDNameDict] = useState(getPageIDNameDict());
  const [error, setError] = useState({isVisible: false, message: ""} as Error);
  const [pageType, setPageType] = useState(PageType.Login);

  function onLogout() {
    //Remove everything except theme.
    localStorage.removeItem(StorageKeys.UserData);
    localStorage.removeItem(StorageKeys.Token);
    localStorage.removeItem(StorageKeys.Pages);
    localStorage.removeItem(StorageKeys.PageIDNameDict);
    localStorage.removeItem(StorageKeys.Menu);
    setLoggedInUser(getUserData());
    navigate("/");
  }

  function onImprint() {
    navigate("/impressum/en");
    setIsMenuOpen(true);
  }

  function onPrivacyPolicy() {
    navigate("/datenschutzerklaerung/en");
    setIsMenuOpen(true); //Hack 
  }

  function handlePagesChange(newPages: PageBase[]) {
    let pageIDNameDictTemp = {} as {[type: number] : string};
    newPages.map((page: PageBase) => {
      pageIDNameDictTemp[page.pageType] = page.name;
    })
    localStorage.setItem(StorageKeys.Pages, JSON.stringify(newPages));
    localStorage.setItem(StorageKeys.PageIDNameDict, JSON.stringify(pageIDNameDictTemp));
    setPages([...newPages]);
    setPageIDNameDict({...pageIDNameDictTemp});
  }

  function getIsMenuOpen(): boolean {
    const menuState = sessionStorage.getItem(StorageKeys.Menu);
    if(menuState){
      return JSON.parse(menuState) as boolean;
    }
    return true;
  }

  function handleMenuChange(menuIsOpen: boolean) {
    if(menuIsOpen){
      localStorage.setItem(StorageKeys.Menu, StorageKeys.True);
      setIsMenuOpen(true);
    } else {
      localStorage.setItem(StorageKeys.Menu, StorageKeys.False);
      setIsMenuOpen(false);
    }
  }

  function handleUserDataChange(userData: UserBase) {
    localStorage.setItem(StorageKeys.UserData, JSON.stringify(userData));
    setLoggedInUser({...userData});
  }

  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }
    setError({...error, isVisible: false});
  };

  return (
    <ThemeProvider theme={isDarkMode? darkTheme: lightTheme}>
      <AppContext.Provider value={{isMenuOpen: isMenuOpen, userData: loggedInUser, setError: setError, setPageType: setPageType, onLogout: onLogout, onImprint: onImprint, onPrivacyPolicy: onPrivacyPolicy} as AppContextType}>
        <Background>
          <Snackbar anchorOrigin={{vertical: 'top', horizontal:"right"}} open={error.isVisible} onClose={handleClose} autoHideDuration={6000}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              <AlertTitle>
                Error
              </AlertTitle>
              {error.message === "" ? "Oops! Something went wrong.": error.message}
            </Alert>
          </Snackbar>
          <Routes>
            <Route path="/" element={
              <React.Fragment>
                <Logo/>
                <Login modal={{isOpen: false, type: ModalType.Success} as Modal} handleUserDataChange={handleUserDataChange} handlePagesChange={handlePagesChange}/>
              </React.Fragment>
              }
            />
            <Route path="/forgotpassword" element={
              <React.Fragment>
                <Logo/>
                <Login modal={{isOpen: true, type: ModalType.ForgotPassword} as Modal} handleUserDataChange={handleUserDataChange} handlePagesChange={handlePagesChange}/>
              </React.Fragment>
              }
            />
            <Route path="/forgotpassword/edit" element={
              <React.Fragment>
                <Logo/>
                <Login modal={{isOpen: true, type: ModalType.EditPassword} as Modal} handleUserDataChange={handleUserDataChange} handlePagesChange={handlePagesChange}/>
              </React.Fragment>
              }
            />
            <Route path="/signup" element={
              <React.Fragment>
                <Logo/>
                <Login modal={{isOpen: true, type: ModalType.Create} as Modal} handleUserDataChange={handleUserDataChange} handlePagesChange={handlePagesChange}/>
              </React.Fragment>
              }
            />
            <Route path="/signup/confirmation"
              element={
                <React.Fragment>
                  <Logo/>
                  <Login modal={{isOpen: true, type: ModalType.Success} as Modal} handleUserDataChange={handleUserDataChange} handlePagesChange={handlePagesChange}/>
                </React.Fragment>
                }
            />
            <Route path="/impressum/de" element={
              <React.Fragment>
                <Logo/>
                <Language url={"/impressum"} language={TextLanguage.German}/>
                <LegalInformation language={TextLanguage.German} fileName={"/legal_information_de.md"}/>
              </React.Fragment>}
            />
            <Route path="/impressum/en" element={
              <React.Fragment>
                <Logo/>
                <Language url={"/impressum"} language={TextLanguage.English}/>
                <LegalInformation language={TextLanguage.English} fileName={"/legal_information_en.md"}/>
              </React.Fragment>}
            />
            <Route path="/datenschutzerklaerung/en" element={
              <React.Fragment>
                <Logo/>
                <Language url={"/datenschutzerklaerung"} language={TextLanguage.English}/>
                <PrivacyStatement language={TextLanguage.English} fileName={"/privacy_statement_en.md"}/>
              </React.Fragment>}
            />
            <Route path="/datenschutzerklaerung/de" element={
              <React.Fragment>
                <Logo/>
                <Language url={"/datenschutzerklaerung"} language={TextLanguage.German}/>
                <PrivacyStatement language={TextLanguage.German} fileName={"/privacy_statement_de.md"}/>
              </React.Fragment>}
            />
            <Route path="/" element={
              <RequireAuth>
                <React.Fragment>
                  <AccountMenu loggedInUser={loggedInUser}/>
                  <TopBar menuOpen={isMenuOpen} isDarkMode={isDarkMode} pageName={pageIDNameDict[pageType]} onClickMenu={() => handleMenuChange(true)}/>
                  <NavigationMenu pages={pages} isDarkMode={isDarkMode} menuOpen={isMenuOpen} handleMenuChange={handleMenuChange} pageType={pageType}/>
                  <Outlet/>
                </React.Fragment>
              </RequireAuth>
            }>
              <Route path="users" element={<ManageUsers/>}/>
              <Route path="jobs" element={<ManageJobs/>}/>
              <Route path="compiler" element={<CompilerVisualization/>}/>
              <Route path="documentation" element={<Documentation/>}/>
              <Route path="editdocumentation" element={<EditDocumentation/>}/>
              <Route path="api" element={<API/>}/>
              <Route path="logs" element={<Logs/>}/>
              <Route path="settings" element={<Settings handleUserDataChange={handleUserDataChange} setIsDarkMode={setIsDarkMode} isDarkMode={isDarkMode}/>}/>
              <Route path="*" element={<Box/>}/>
            </Route>
          </Routes>
          </Background>
        </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
