import React, {useState} from 'react';
import { Area, Operation, Ion } from './models';
import { useTheme } from '@mui/material/styles';

interface Props {
  storage: Ion[],
  compute: Ion[],
  tempstorage: Ion[],
  spam: Ion[],
  radius: number,
  comment: string,
  ionToPartnersMap: {[ionID: string]: string[]},
  ionToPartnerIndexMap: {[ionID: string]: number},
  chipCutoffY: number
}

// 0 --> props.radius00
// 0 --> props.radius00

const red: string = "rgb(199,56,52)";
const chipLength: number = 1000;

function IonChip(props: Props) {

    const theme = useTheme();
    const [showNextPartnerQubits, setShowNextPartnerQubits] = useState(false);

    function onClickIon() {
        setShowNextPartnerQubits(!showNextPartnerQubits);
    }

    function displayIon(ion: Ion) {

        //TODO: get colors from global theme.
        let ionFilling: string = theme.palette.primary.light;
        if(ion.operationHistory.length > 0){
            let lastOperation = ion.operationHistory[ion.operationHistory.length - 1];
            // if(lastOperation === Operation.Measure){
            //     ionFilling = "white"
            // } else 
            if (lastOperation === Operation.Gate) {
                ionFilling = theme.palette.error.dark;
            }
        }

        return (
            <g key={"g" + ion.x.toString() + "," + ion.y.toString()}>
                    <circle 
                        key={ion.x.toString() + "," + ion.y.toString()}
                        cx={ion.x} 
                        cy={ion.y} 
                        r={props.radius} 
                        fill={ionFilling}
                        onClick={onClickIon}
                        style={{cursor:"pointer"}}
                    />
                    <text
                        xmlSpace={"preserve"}
                        tabIndex={0}
                        key={"t" + ion.x.toString() + "," + ion.y.toString()}
                        x={ion.x} 
                        y={ion.y}
                        onClick={onClickIon} 
                        style={{fontFamily: "arial", cursor: "pointer", textAnchor: "middle", dominantBaseline: "central", fontSize: Math.floor(props.radius * 1.5).toString(), fill: "black", outline: 0}}
                    >
                        {ion.label}
                    </text>
                        {showNextPartnerQubits &&
                            <text
                                xmlSpace={"preserve"}
                                tabIndex={0}
                                key={"partner" + ion.x.toString() + "," + ion.y.toString()}
                                x={(ion.area === Area.Compute || ion.area === Area.Spam)? ion.x + 3 * props.radius : ion.x} 
                                y={(ion.area === Area.Compute || ion.area === Area.Spam)? ion.y : ion.y - 3 * props.radius}
                                style={{fontFamily: "arial", textAnchor: "middle", dominantBaseline: "central", fontSize: Math.floor(props.radius * 1.5).toString(), fill: theme.palette.text.secondary, outline: 0}}
                            >
                                {ion.label in props.ionToPartnersMap && props.ionToPartnersMap[ion.label].length > 0 && 
                                 ion.label in props.ionToPartnerIndexMap && props.ionToPartnerIndexMap[ion.label] >= 0 && 
                                 props.ionToPartnerIndexMap[ion.label] < props.ionToPartnersMap[ion.label].length?
                                    props.ionToPartnersMap[ion.label][props.ionToPartnerIndexMap[ion.label]] : "X"
                                }
                            </text>
                    }
            </g>
        )
    }

    return (
    <g key="group">
        <line
            key={"hline"}
            x1={0} 
            y1={chipLength/2} 
            x2={chipLength} 
            y2={chipLength/2} 
            style={{stroke: theme.palette.divider,strokeWidth:4}} 
        />
        <line
            key={"vline"}
            x1={chipLength/2} 
            y1={60} 
            x2={chipLength/2} 
            y2={chipLength - 60} 
            style={{stroke: theme.palette.divider,strokeWidth:4}} 
        />
        <text
            key={"storage"}
            x={chipLength/4} 
            y={chipLength/2 + 60}
            style={{fontFamily: "arial", cursor: "pointer", textAnchor: "middle", dominantBaseline: "central", fontSize: "35", fill: theme.palette.text.secondary, outline: 0}}
        >
            {"Storage"}
        </text>
        <text
            key={"SPAM"}
            x={chipLength/2} 
            y={10}
            style={{fontFamily: "arial", cursor: "pointer", textAnchor: "middle", dominantBaseline: "hanging", fontSize: "35", fill: theme.palette.text.secondary, outline: 0}}
        >
            {"SPAM"}
        </text>
        <text
            key={"compute"}
            x={chipLength/2} 
            y={chipLength-10}
            style={{fontFamily: "arial", cursor: "pointer", textAnchor: "middle", dominantBaseline: "auto", fontSize: "35", fill: theme.palette.text.secondary, outline: 0}}
        >
            {"Compute"}
        </text>
        <text
            key={"tempstorage"}
            x={3*chipLength/4} 
            y={chipLength/2 + 60}
            style={{fontFamily: "arial", cursor: "pointer", textAnchor: "middle", dominantBaseline: "central", fontSize: "35", fill: theme.palette.text.secondary, outline: 0}}
        >
            {"Tempstorage"}
        </text>
        {
            props.storage.map((ion: Ion) =>
                displayIon(ion)
            )
        }
        {
            props.tempstorage.map((ion: Ion) =>
                displayIon(ion)
            )
        }
        {
            props.compute.map((ion: Ion) =>
                displayIon(ion)
            )
        }
        {
            props.spam.map((ion: Ion) =>
                displayIon(ion)
            )
        }
        <text
            xmlSpace={"preserve"}
            tabIndex={0}
            key={"comment"}
            x={50} 
            y={props.chipCutoffY/2 + 100} 
            style={{fontFamily: "arial", dominantBaseline: "central", 
                    fontSize: props.comment.length > 35? Math.floor(1800/props.comment.length).toString() : "40", 
                    fill: "white", 
                    outline: 0
            }}
        >
            {props.comment}
        </text>
    </g>
  );
}

export default IonChip;