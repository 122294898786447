import React, {useEffect, useState} from "react";
import { PageType, TextLanguage} from "../../components/models";
import PageLayout from "../../components/PageLayout";
import MarkdownRenderer from "../../components/MarkdownRendererSimple";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

function ErrorFallback({error, resetErrorBoundary}: any) {
    return (
      <Box role="alert" sx={{height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <Box>
            <Typography color="text.primary">Something went wrong. The documentation cannot be rendered.</Typography>
        </Box>
      </Box>
    )
}

interface Props {
    fileName: string,
    language: TextLanguage
}

export default function LegalInformation(props: Props) {

    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        fetch(props.fileName)
        .then(response => response.text())
        .then(data => {
          setMarkdown(data);
        })
        .catch(error => {
          console.error('Error loading markdown file:', error);
        });
    },[props.fileName])

    return (
        <PageLayout pageType={PageType.LegalInformation}>
            <Box sx={{display: "flex", width: "100%", height: "100%", justifyContent: "center", mt: 6, overflow: "hidden"}}>
                <Box sx={{overflow: "scroll", textOverflow: "ellipsis", wordWrap: "break-word", display: "block", height: "100%", width: "100%"}}>
                    <Box sx={{overflow: "hidden", textOverflow: "ellipsis", wordWrap: "break-word", display: "block", margin: "auto"}} maxWidth={"md"}>
                        <MarkdownRenderer tocHeader={props.language == TextLanguage.German? "Übersicht": "Contents"} markdown={markdown} errorFallback={ErrorFallback}/>
                    </Box>
                </Box>
            </Box>
        </PageLayout>
    )
}
