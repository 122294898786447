import React from "react";
import { PageType } from "../../components/models";
import PageLayout from "../../components/PageLayout";

export default function API() {

    return (
        <PageLayout pageType={PageType.API}>
            <div></div>
        </PageLayout>
    )
}