import { Box, Tooltip } from "@mui/material";
import React from "react";

interface PropsDateTime {
    dateTime: string
}

export default function DisplayDateTime(props: PropsDateTime) {

    const date = new Date(props.dateTime);

    return (
        <Box sx={{display: "flex"}}>
            <Tooltip title={date.toLocaleDateString() + " " + date.toLocaleTimeString()} >
                <Box>{date.toLocaleDateString() + " " + date.toLocaleTimeString()}</Box>
            </Tooltip>
        </Box>
    )
}
