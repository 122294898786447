export type Ion = {
    x: number,
    y: number,
    label: string,
    area: Area | undefined,
    operationHistory: Operation[]
}

export enum Operation {
    Prepare = 0,
    Gate = 1,
    Measure = 2
}

export enum Area {
    Storage = 0,
    Tempstorage = 1,
    Compute = 2,
    Spam = 3
}